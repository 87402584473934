import React from "react";
import Header from "../containers/Header/Header.container";
import CtHome from "./Home-content/Home.content";

const Home: React.FC = () => {
  return (
    <>
      <Header title="UNE CAUSE DE PLUS" subtitle="CONSULTEZ LES PROFILS" />
      <CtHome />
    </>
  );
};

export default Home;
