import React from 'react';
import SigninForm  from '../forms/SigninForm/SigninForm';

const Signin: React.FC = () => {
  return (
    <>
      <SigninForm />
    </>
  )
}

export default Signin;
