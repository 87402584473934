import React from 'react'
import SignupForm from '../forms/SignupForm/SignupForm'

const Signup: React.FC = () => {
  return (
    <>
      <SignupForm />
    </>
  )
}

export default Signup;
