import React from 'react';
import Header from '../containers/Header/Header.container';
import CtStaff from './Staff-content /Staff.content';

const Staff:React.FC = () => {
  return (
    <>
      <Header 
        title="LE STAFF"
        subtitle="LES PERSONNES DERRIÈRE ZAWAJ SUNNAH" />
      <CtStaff/>
    </>
  )
}

export default Staff;