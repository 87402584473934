import React from 'react';
import Header from '../containers/Header/Header.container';
import CtFavorite from './Favorite-content/Favorite.content';

const Favorite:React.FC = () => {
  return (
    <>
      <Header 
        title="MES FAVORIS"
        subtitle="LES PROFILS QUI M'INTÉRESSENT" />
      <CtFavorite/>
    </>
  )
}

export default Favorite;