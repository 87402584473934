import React, { useEffect, useState } from "react";
import { routePaths } from "@/config";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { apiResetPasswordMember } from "../../api/auth.api";
import { useFormik } from "formik";
import { TResetPassword } from "../../types";
import { Toastify } from "@/utils/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import * as yup from "yup";
import "./ResetPasswordForm.styles.scss";
import { selectEmail } from "@/store/reducers/temp_data/temp_data.selector";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { store } from "@/store";
import { updateEmail } from "@/store/reducers/temp_data/temp_data.actions";

const ResetPasswordForm = () => {
  const email_from_redux = useSelector(selectEmail);
  const parsed = queryString.parse(window.location.search);
  const emailFromUrl = (parsed?.email || "") as string;
  const tokenFromUrl = (parsed?.token || "") as string;

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const validationSchema = yup.object({
    new_password: yup
      .string()
      .required("le mot de passe est obligatoire")
      .min(8, "Votre mot de passe doit contenir au moins 8 caratères"),
    confirmed_password: yup
      .string()
      .required("le mot de passe est obligatoire")
      .oneOf(
        [yup.ref("new_password")],
        "Les mots de passes doivent être identiques"
      ),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirmed_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log({ parsed });
      const dataToSend: TResetPassword = {
        email: email_from_redux || emailFromUrl,
        token: tokenFromUrl,
        password: values.confirmed_password,
      };
      store.dispatch(updateEmail(email_from_redux || emailFromUrl));
      console.log({ dataToSend });
      resetPasswordSubmit(dataToSend);
    },
  });

  const resetPasswordSubmit = (data: TResetPassword) => {
    setLoading(true);

    apiResetPasswordMember(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        navigate(routePaths.authChildren.signInAbsolute, { replace: false });
        Toastify(
          `Votre mot de passe été réinitialiser avec succès .`,
          "success",
          5000
        );
      })
      .catch((error: any) => {
        console.log({ error });
        setLoading(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  // useEffect(() => {
  //   email_from_redux === "" &&
  //     emailFromUrl === "" &&
  //     Toastify(
  //       `Veuillez saisir à nouveau votre addresse mail svp`,
  //       "warning",
  //       4000
  //     );

  //   email_from_redux === "" &&
  //     emailFromUrl === "" &&
  //     navigate(routePaths.authChildren.forgottenPasswordAbsolute, {
  //       replace: false,
  //     });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="reset-password-container">
      <div className="header-part">
        <h1 className="title">
          {emailFromUrl !== ""
            ? "Création du mot de passe"
            : "Réinitialiser le mot de passe"}
        </h1>
      </div>
      <form className="form-part" onSubmit={formik.handleSubmit}>
        <TextField
          placeholder="Nouveau mot de passe"
          variant="outlined"
          label="Nouveau mot de passe"
          type={showPassword1 ? "text" : "password"}
          name="new_password"
          onChange={formik.handleChange}
          value={formik.values.new_password}
          error={
            formik.touched.new_password && Boolean(formik.errors.new_password)
          }
          helperText={formik.touched.new_password && formik.errors.new_password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: "15px" }}
        />
        <TextField
          placeholder="Mot de passe"
          variant="outlined"
          label="Confirmation de mot de passe"
          type={showPassword2 ? "text" : "password"}
          name="confirmed_password"
          onChange={formik.handleChange}
          value={formik.values.confirmed_password}
          error={
            formik.touched.confirmed_password &&
            Boolean(formik.errors.confirmed_password)
          }
          helperText={
            formik.touched.confirmed_password &&
            formik.errors.confirmed_password
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: "15px" }}
        />
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          {emailFromUrl !== ""
            ? "CRÉER LE MOT DE PASSE"
            : "RÉINITIALISER LE MOT DE PASSE"}
        </LoadingButton>
      </form>
      <div className="footer-part">
        <Link
          className="link-primary"
          to={routePaths.authChildren.signInAbsolute}
        >
          {" "}
          Connexion{" "}
        </Link>
        <a
          href="https://zawaj-sounnah.com/"
          target="_blank"
          rel="noreferrer"
          className="link-gray"
        >
          RETOURNER À ZAWAZ-SOUNNAH.COM
        </a>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
