import React from 'react'
import styled from 'styled-components'

const NoData:React.FC = () => {
  return (
    <NoDataContainer>
        <div className="card">
            <img src={require("@/assets/images/jpeg/errorHandlerImage.jpeg")} alt="no-data" />
            <p> Aucune donnée disponible</p>
        </div>
    </NoDataContainer>
  )
}

const NoDataContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;

    .card{
        width: 100%;
        height: fit-content;
        background: transparent;
        border-radius: 10px;
        padding: 30px;
        //background: rgba(var(--ui-tertiary-rgb),0.1);
        //box-shadow: 0px 0px 30px rgba(var(--ui-tertiary-rgb),0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        img{
            height: 150px;
            width: 150px ;
            border-radius: 50%;
            opacity: 1;
        }

        p{
            color: var(--ui-medium);
            font-size: var(--title-size) ;
            text-align: center ;
        }
    }

`;

export default NoData;