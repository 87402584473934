import { routePaths } from "@/config";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./MyAccount.styles.scss";
import useUserRestrictions from "@/hooks/useUserRestriction";
// import ModalVerifiedEmail from "@/components/Modal-verified-email/ModalVerifiedEmail";
import CModalRemindPay from "@/components/Modal-remind-pay/ModalRemindPay";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import { Grow } from "@mui/material";

const CtMyAccount = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    isUserEmailVerified,
    didUserHasToPay,
    isUserNew,
    isUserFullAccessAutorized,
  } = useUserRestrictions();

  useEffect(() => {
    if (isUserNew) {
      navigate(routePaths.profileChildren.myAccountChildren.ficheAbsolute, {
        replace: true,
      }); // à la demande de momo
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserNew]);

  useEffect(() => {
    window.addEventListener("load", () => {
      console.log("🌧️load");
    });
  }, []);

  return (
    <>
      <div className="ct-my-account">
        {isUserEmailVerified ? (
          <Outlet />
        ) : (
          <>
            <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
              <div className="ct-home">
                <UserRestrictionDisplayer />
              </div>
            </Grow>
          </>
        )}
      </div>{" "}
      <>
        {/* <ModalVerifiedEmail open={!isUserEmailVerified} /> */}
        <CModalRemindPay
          open={location.state?.from !== "inside" && didUserHasToPay}
        />
      </>
    </>
  );
};

export default CtMyAccount;
