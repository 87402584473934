export const socketChannels = {
  member_conversation_messages: "get/messages",
  messages_mark_as_read: "get/read-message",
  new_favorite_notif: "get/fans/new",
  delete_favorite_notif: "get/fans/delete",
  interface_setting_channel: "get/interface-settings",
  nbre_message_available_count: "get/today-count",
  is_moderation_open: "get/app-planning",
  profile_updated_me: "get/profile/me",
  exchange_demand: "get/conversations/member-conversation-update",
  get_live_channel: "get/live-session",
  delete_live_channel: "delete/live-session",
  notification_channel: "get/notifications",
};
