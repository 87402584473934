import { ProfileRoutes } from "@/features/profile";
import { Navigate, RouteObject } from "react-router-dom";
import { routePaths } from "../config";

export const protectedRoutes: RouteObject[] = [
    {
        path: routePaths.profile + "/*",
        element: <ProfileRoutes />,
    },
    { path: "*", element: <Navigate to={`${routePaths.profile}`} /> }
];