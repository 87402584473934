import React from 'react';
import { CircularProgress } from '@mui/material';
import { SuspenseFallbackContainer } from './SuspenseFallback.styles';
import logo_mini from "@/assets/images/png/logo-mini.png";


export const SuspenseFallback:React.FC = () => {

  return (
    <SuspenseFallbackContainer >
            <img src={logo_mini} alt="" />
            <span> Chargement </span>
            <CircularProgress />
    </SuspenseFallbackContainer>
  )
}
