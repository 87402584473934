import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import { InlineIcon } from "@iconify/react";
import { Button, IconButton } from "@mui/material";
import { HighlightOffRounded } from "@mui/icons-material";
import "./ModalRejectedModif.styles.scss";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import { TMemberProfile } from "@/features/auth/types";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";

interface CModalRejectedModifProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fromAccount?: boolean;
}
const CModalRejectedModif = ({
  open,
  setOpen,
  fromAccount = false,
}: CModalRejectedModifProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const navigate = useNavigate();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grow in={open}>
        <div className="c-modal-rejected-modif ">
          <IconButton
            className="close-icon"
            size="large"
            color="inherit"
            onClick={() => {
              setOpen(false);
            }}
          >
            <HighlightOffRounded />
          </IconButton>

          {memberProfileFromStore?.status?.reason?.length > 0 && (
            <>
              <h3>
                <b> RAPPORT DE MODÉRATION </b>
              </h3>
              <br />

              <div className="c-modal-rejected-modif__content">
                <p style={{ fontWeight: "bold" }}>
                  {" "}
                  À date, la(les) modification(s) suivante(s) est(sont) à
                  apporter à votre profil sauf si c'est déjà fait:
                </p>
                <ul
                  style={{
                    textAlign: "left",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  {memberProfileFromStore?.status?.reason?.map((x, index) => (
                    <li key={index}>
                      {x.category === "identity" && (
                        <>
                          Dans la{" "}
                          <b
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .ficheAbsolute,
                                { replace: true }
                              );
                              setOpen(false);
                            }}
                          >
                            <InlineIcon
                              icon="akar-icons:link-out"
                              fontSize={12}
                            />
                            Fiche d'information
                          </b>
                          :
                        </>
                      )}
                      {x.category === "profile" && (
                        <>
                          Dans la rubrique{" "}
                          <b
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "1" },
                                }
                              );
                              setOpen(false);
                            }}
                          >
                            <InlineIcon
                              icon="akar-icons:link-out"
                              fontSize={12}
                            />
                            Profil
                          </b>
                          :
                        </>
                      )}
                      {x.category === "religiousAspects" && (
                        <>
                          Dans la rubrique{" "}
                          <b
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "2" },
                                }
                              );
                              setOpen(false);
                            }}
                          >
                            <InlineIcon
                              icon="akar-icons:link-out"
                              fontSize={12}
                            />
                            Aspect religieux
                          </b>
                          :
                        </>
                      )}
                      {x.category === "religiousPractices" && (
                        <>
                          Dans la rubrique{" "}
                          <b
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "3" },
                                }
                              );
                              setOpen(false);
                            }}
                          >
                            <InlineIcon
                              icon="akar-icons:link-out"
                              fontSize={12}
                            />
                            Pratiques religieuses
                          </b>
                          :
                        </>
                      )}
                      {x.category === "healthAndDisability" && (
                        <>
                          Dans la rubrique{" "}
                          <b
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "4" },
                                }
                              );
                              setOpen(false);
                            }}
                          >
                            <InlineIcon
                              icon="akar-icons:link-out"
                              fontSize={12}
                            />
                            Santé et handicap
                          </b>
                          :
                        </>
                      )}
                      <b className="value">{x.value}</b>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </Grow>
    </Modal>
  );
};

export default CModalRejectedModif;
