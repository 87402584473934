import { StepType } from "@reactour/tour";

export const API_URL = process.env.REACT_APP_API_URL_5 as string;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL_5 as string;

console.log(API_URL);
console.log(SOCKET_URL);

export const DOCUMENT_TITLE = {
  login: "Login | Zawaj Sounnah",
};

export const appTourSteps: StepType[] = [
  {
    selector: ".detail-btn",
    content:
      "Vous pouvez cliquer ici pour masquer ou voir les détails liés à cette conversation",
    position: "bottom",
  },
  {
    selector: ".refresh-conversation-btn",
    content:
      "Vous pouvez cliquer ici pour afficher les autres options relatives à cette conversation. Ex: voir le profil, clôturer l'échange ...",
    position: "bottom",
  },
  {
    selector: ".toogle-expand-shrink",
    content:
      "Vous pouvez cliquer ici pour agrandir en plein écran ou réduire cette conversation",
    position: "bottom",
  },
];
