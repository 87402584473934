import {
  Box,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Toastify } from "@/utils/toast";
import * as yup from "yup";
import "./Pratiques.step.styles.scss";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import { apiSavePratique } from "@/features/profile/api/profile.api";
import { store } from "@/store";
import { updateMemberProfile } from "@/store/reducers/member/member.actions";
import { checkTrueOrFalse } from "../../../utils/account.utils";
import { TMemberProfile } from "@/features/auth/types";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";

interface PratiquesStepProps {
  chooseStep: React.Dispatch<React.SetStateAction<number>>;
  scrollTop: () => void;
}
const PratiquesStep = ({ chooseStep, scrollTop }: PratiquesStepProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    rapport_mosquee: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      cinq_prieres: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousPractices?.prayFivePrayers
      ),
      vendredi_mosquee: checkTrueOrFalse(
        memberProfileFromStore?.infos?.religiousPractices?.prayFridayMosque
      ),
      rapport_mosquee:
        memberProfileFromStore?.infos?.religiousPractices
          ?.relationshipToMosque || "",
      rapport_lecture:
        memberProfileFromStore?.infos?.religiousPractices
          ?.relationshipToQuranReading || "",
      rapport_arabe:
        memberProfileFromStore?.infos?.religiousPractices
          ?.relationshipToArabicLanguage || "",
      niveau_religieux:
        memberProfileFromStore?.infos?.religiousPractices
          ?.levelOfReligiousEducation || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);

      if (_.isEqual(formik.initialValues, values)) {
        Toastify(
          `Aucun changement apporté à vos pratiques religieuses.`,
          "warning",
          3000
        );
      } else {
        const dataToSend = {
          category: "religiousPractices",
          prayFivePrayers: values.cinq_prieres,
          prayFridayMosque: values.vendredi_mosquee,
          relationshipToMosque: values.rapport_mosquee.trim(),
          relationshipToQuranReading: values.rapport_lecture.trim(),
          relationshipToArabicLanguage: values.rapport_arabe.trim(),
          levelOfReligiousEducation: values.niveau_religieux,
        };
        console.log(dataToSend);
        pratiqueStepSubmit(dataToSend);
      }
    },
  });

  const navigate = useNavigate();
  const pratiqueStepSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    apiSavePratique(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const profile = response.data as TMemberProfile;
        store.dispatch(updateMemberProfile({ ...profile }));
        Toastify(
          `Les informations de vos pratiques religieuses ont bien été soumises à notre équipe de modération`,
          "success",
          5000
        );

        chooseStep(3);
        navigate(routePaths.profileChildren.myAccountChildren.formAbsolute, {
          replace: true,
          state: { formStep: "4" },
        });
        scrollTop();
      })
      .catch((error: any) => {
        setLoading(false);

        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const reset = () => {
    formik.setValues({
      cinq_prieres: false,
      vendredi_mosquee: false,
      rapport_mosquee: "",
      rapport_lecture: "",
      rapport_arabe: "",
      niveau_religieux: "",
    });
  };

  return (
    <form className="form-part-pratiques" onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography> Priez-vous les 5 prières ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.cinq_prieres ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="cinq_prieres"
                      checked={formik.values.cinq_prieres}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography> Priez-vous le vendredi à la mosquée ? </Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.vendredi_mosquee ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="vendredi_mosquee"
                      checked={formik.values.vendredi_mosquee}
                      onChange={formik.handleChange}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label">
                {" "}
                Votre rapport à la mosquée
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="rapport_mosquee"
                onChange={formik.handleChange}
                value={formik.values.rapport_mosquee}
              ></textarea>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label">
                {" "}
                Votre rapport à la lecture du Qour’an El Karim{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="rapport_lecture"
                onChange={formik.handleChange}
                value={formik.values.rapport_lecture}
              ></textarea>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label">
                {" "}
                Votre rapport à la langue arabe{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={4}
                name="rapport_arabe"
                onChange={formik.handleChange}
                value={formik.values.rapport_arabe}
              ></textarea>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <FormControl fullWidth color="primary" className="niveau-radio-row">
              <FormLabel id="id-niveau">
                Votre niveau d’instruction religieuse
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="id-niveau"
                className="row-radio-buttons-group"
                name="niveau_religieux"
                value={formik.values.niveau_religieux}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="NONE"
                  control={<Radio />}
                  label="Aucun"
                />
                <FormControlLabel
                  value="BEGINNER"
                  control={<Radio />}
                  label="Débutant(e)"
                />
                <FormControlLabel
                  value="INTERMEDIATE"
                  control={<Radio />}
                  label="Intermédiaire"
                />
                <FormControlLabel
                  value="ADVANCED"
                  control={<Radio />}
                  label="Avancé(e)"
                />
                <FormControlLabel
                  value="SENIOR"
                  control={<Radio />}
                  label="Confirmé(e)"
                />
                <FormControlLabel
                  value="NOT_PRONOUNCED"
                  control={<Radio />}
                  label="Ne se prononce pas"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Grid item xs={12} sm={12} lg={3} xl={2}>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => {
              reset();
            }}
          >
            {" "}
            Effacer
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={5} xl={5}>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            type="submit"
          >
            {" "}
            Valider et passer à "Santé et handicap"
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default PratiquesStep;
