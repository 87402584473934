import { routePaths } from "@/config";
import { Route, Routes } from "react-router-dom";
import Subscription from "../../Subscription.page";
import DeleteAccount from "../components/DeleteAccount/DeleteAccount";
import DesactivateSubscription from "../components/DesactivateSubscription/DesactivateSubscription";


export const SubscriptionRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<Subscription />}>
              <Route path={routePaths.profileChildren.subscriptionChildren.deleteAccount} element={ <DeleteAccount />} />
              <Route path={routePaths.profileChildren.subscriptionChildren.desactivateSubscription} element={ <DesactivateSubscription />} />
          </Route>
      </Routes>
    );
};