import { TMemberCurrentExchange } from "@/features/profile/types";
import {
  Button,
  Grow,
  IconButton,
  Modal,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import EchangeInfos from "../components/Echange-Infos/EchangeInfos";
import EchangeMessage from "../components/Echanges-message/EchangeMessage";
import "./DetailEchange.styles.scss";
import { store } from "@/store";
import { updateOpenDiscussion } from "@/store/reducers/general_param/general_param.actions";
import { InlineIcon } from "@iconify/react";
import { useTour } from "@reactour/tour";
import { selectTourStepIsOpen } from "@/store/reducers/general_param/general_param.selector";
import { useSelector } from "react-redux";

interface DetailEchangeProps {
  memberCurrentExchange: TMemberCurrentExchange;
  openDiscussion: boolean;
}
const DetailEchange = ({
  memberCurrentExchange,
  openDiscussion,
}: DetailEchangeProps) => {
  const theme = useTheme();
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [refresh, setRefresh] = React.useState(false);
  const { setIsOpen } = useTour();
  const tour_step_is_open_from_store = useSelector(selectTourStepIsOpen);

  const [toogleTopPartInConversation, setToogleTopPartInConversation] =
    React.useState(true);
  const handleToogleTopPartInConversation = () =>
    setToogleTopPartInConversation(!toogleTopPartInConversation);

  useEffect(() => {
    if (matches_xl && openDiscussion) {
      setIsOpen(tour_step_is_open_from_store);
      console.log("😅 In Details - matches_xl : ", matches_xl);
      console.log("😅 In Details - matches_sm : ", matches_sm);
      console.log("😅 In Details - openDiscussion : ", openDiscussion);
      console.log(
        "😅 In Details - tour_step_is_open_from_store : ",
        tour_step_is_open_from_store
      );
    }
  }, [
    matches_xl,
    matches_sm,
    openDiscussion,
    setIsOpen,
    tour_step_is_open_from_store,
  ]);

  const refreshAction = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  };

  return (
    <>
      <div className="detail-exchange-container">
        {!matches_xl && (
          <div className="infos-part">
            {" "}
            <EchangeInfos
              isDiscussing={true}
              memberCurrentExchange={memberCurrentExchange}
            />
          </div>
        )}
        {matches_xl && (
          <div className="option-part">
            <Button
              color="primary"
              className="back-btn"
              startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
              onClick={() => {
                store.dispatch(updateOpenDiscussion(false));
              }}
            >
              Retour
            </Button>

            <Button
              color="primary"
              variant="contained"
              className="detail-btn"
              startIcon={
                <InlineIcon
                  icon={
                    toogleTopPartInConversation
                      ? "heroicons-outline:chevron-up"
                      : "heroicons-outline:chevron-down"
                  }
                />
              }
              onClick={handleToogleTopPartInConversation}
            >
              {toogleTopPartInConversation ? "Masquer " : "Révéler "}
            </Button>

            <Button
              color="primary"
              className="opt-btn aide-btn-exchange"
              startIcon={<InlineIcon icon="material-symbols:sync-rounded" />}
              onClick={refreshAction}
            >
              Actualiser
            </Button>
          </div>
        )}
        <div className="message-part">
          {openDiscussion && (
            <EchangeMessage
              toogleTopPartInConversation={toogleTopPartInConversation}
              setToogleTopPartInConversation={setToogleTopPartInConversation}
              memberCurrentExchange={memberCurrentExchange}
              openDiscussion={openDiscussion}
              refreshing={refresh}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DetailEchange;
