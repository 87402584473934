import React, { useEffect, useState } from "react";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import * as moment from "moment";
import "moment/locale/fr";
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./DesactivateSubscription.styles.scss";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { Toastify } from "@/utils/toast";
import {
  apiActivateAccount,
  apiActivateAccountPaypal,
  apiDesactivateAccount,
  apiGetPurchaseInfo,
  apiGetSubscriptionPlan,
} from "@/features/profile/api/subscription.api";
import {
  TPaymentInfos,
  TSubscriptionPlan,
} from "@/features/profile/types/types";
import classNames from "classnames";
import { store } from "@/store";
import { updateIsSubscriptionActive } from "@/store/reducers/member/member.actions";
import { useNavigate } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import { routePaths } from "@/config";
import { TMemberProfile } from "@/features/auth/types";
import useUserRestrictions from "@/hooks/useUserRestriction";

const DesactivateSubscription = () => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const { didUserHasToPay, isUserFullAccessAutorized } = useUserRestrictions();

  const navigate = useNavigate();

  // RESILATION PART

  const [loading, setLoading] = useState(false);

  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const validationSchema = yup.object({
    reason_radio: yup
      .string()
      .required(" La raison est obligatoire, svp faites un choix "),
  });

  const formik = useFormik({
    initialValues: {
      reason_radio: "",
      reason_other: "",
      amelioration: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClickOpenLogout();
    },
  });

  const desactivateAccountSubmit = (data: any) => {
    const dataTosend = {
      reason: data.reason_radio === "" ? data.reason_other : data.reason_radio,
      suggestion: data.amelioration,
    };
    console.log(dataTosend);

    const purchaseId =
      memberProfileFromStore?.account?.subscription?.lastPayment?.purchaseId;

    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    apiDesactivateAccount(purchaseId, dataTosend)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        //store.dispatch(updateIsSubscriptionActive(false));
        //initFormuleAbo();
        Toastify(`Désactivation réussie`, "success", 5000);
      })
      .catch((error: any) => {
        setLoading(false);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const desactivate = () => {
    // setIsDesactivate(true);
    desactivateAccountSubmit(formik.values);
    handleCloseLogout();
  };

  // END of RESILATION PART

  // PAYMENT PART

  const [loadingActivate, setLoadingActivate] = useState(false);

  const [openLogoutActivate, setOpenLogoutActivate] = useState(false);
  const handleClickOpenLogoutActivate = () => setOpenLogoutActivate(true);
  const handleCloseLogoutActivate = () => setOpenLogoutActivate(false);

  const [loadingFormule, setLoadingFormule] = useState(false);
  const [formuleList, setFormuleList] = useState<TSubscriptionPlan[]>([]);

  useEffect(() => {
    initFormuleAbo();
    getPaymentInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initFormuleAbo = () => {
    setLoadingFormule(true);

    apiGetSubscriptionPlan()
      .then((response: any) => {
        setLoadingFormule(false);
        console.log(response);

        let tempList: TSubscriptionPlan[] = response.data.result.map(
          (data: any) => {
            return {
              id: data._id,
              name: data.name,
              description: data.description,
              duration: data.duration.count + "" + data.duration.unit,
              currency: data.currency,
              price_for_men: data.priceForMen,
              price_for_women: data.priceForWomen,
            };
          }
        );

        //console.log([...tempList]);
        setFormuleList([...tempList]);
      })
      .catch((error) => {
        setLoadingFormule(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const validationSchemaActivate = yup.object({
    formule: yup
      .string()
      .required(" La formule est obligatoire, svp faites un choix "),
  });

  const formikActivate = useFormik({
    initialValues: {
      formule: "",
    },
    validationSchema: validationSchemaActivate,
    onSubmit: (values) => {
      handleClickOpenLogoutActivate();
    },
  });

  const activateAccountSubmit = (data: any) => {
    console.log(data);

    const dataToSend = {
      paymentMean: "STRIPE",
      paymentPlan: data,
    };

    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoadingActivate(true);

    apiActivateAccount(dataToSend)
      .then((response: any) => {
        setLoadingActivate(false);
        console.log(response);

        console.log();

        if (typeof response.data !== "string") {
          const redirect_url = response.data.purchaseUrl;
          const linkToStripe = document.createElement("a");
          linkToStripe.setAttribute("href", redirect_url);
          // linkToStripe.setAttribute('target','_blank');
          linkToStripe.click();
          linkToStripe.remove();
        } else {
          Toastify(`${response.data}`, "warning", 5000);
        }
      })
      .catch((error: any) => {
        setLoadingActivate(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const paypalInitPayment = (data: any) => {
    setLoadingActivate(true);

    console.log(data);

    const dataToSend = {
      paymentMean: "PAYPAL",
      paymentPlan: data,
    };

    apiActivateAccountPaypal(dataToSend)
      .then((response: any) => {
        console.log(response);
        setLoadingActivate(false);
        const redirect_url = response.data.purchaseUrl;
        const linkToPaypal = document.createElement("a");
        linkToPaypal.setAttribute("href", redirect_url);
        linkToPaypal.click();

        linkToPaypal.remove();
      })
      .catch((error: any) => {
        setLoadingActivate(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };
  // END of PAYMENT PART

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState<TPaymentInfos | null>(null);
  const getPaymentInfo = () => {
    const dataToSend =
      memberProfileFromStore?.account?.subscription?.lastPayment?.purchaseId ||
      "";

    dataToSend !== "" && setLoadingPayment(true);
    dataToSend !== "" &&
      apiGetPurchaseInfo(dataToSend)
        .then((response: any) => {
          setLoadingPayment(false);
          console.log(response);
          const data = response.data as TPaymentInfos;
          setPaymentInfos({ ...data });
        })
        .catch((error: any) => {
          setLoadingPayment(false);
        });
  };

  return (
    <>
      {isUserFullAccessAutorized && (
        <div className="ct-desactivate-subscription-nav-part">
          <Button
            color="primary"
            className="nav-part__btn-nav"
            onClick={() => {
              navigate(routePaths.profileChildren.homeAbsolute, {
                replace: true,
              });
            }}
            startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
          >
            Aller à la liste des membres
          </Button>
        </div>
      )}
      <PerfectScrollbar className="ct-desactivate-subscription">
        {didUserHasToPay && (
          <form
            className="desactivate-box"
            onSubmit={formikActivate.handleSubmit}
          >
            <Box sx={{ width: "100%" }}>
              <Grid container rowSpacing={4} columnSpacing={2}>
                {memberProfileFromStore?.account?.subscription?.isFree
                  ?.value && (
                  <Grid item xs={12} sm={12} lg={12} style={{ width: "100%" }}>
                    <div className="recap-part">
                      <div className="text-part">
                        <h3> ABONNEMENT GRATUIT</h3>
                        <p>
                          {" "}
                          Depuis le &nbsp;
                          <b>
                            {moment
                              .default(paymentInfos?.startDate)
                              .format("L")}
                          </b>
                        </p>
                        {false && (
                          <p>
                            {`Jusqu'au`}
                            &nbsp;
                            <b>
                              {moment
                                .default(paymentInfos?.endDate)
                                .format("L")}
                            </b>
                          </p>
                        )}
                      </div>
                      <div className="infos-part">
                        <p>
                          Plan gratuit
                          <br />
                          <b>0€ / </b>mois
                        </p>
                      </div>
                    </div>
                  </Grid>
                )}

                {!memberProfileFromStore?.account?.subscription?.isFree
                  ?.value && (
                  <Grid item xs={12} sm={12} lg={12}>
                    {(memberProfileFromStore?.account?.subscription?.lastPayment
                      ?.hasExpired === null ||
                      memberProfileFromStore?.account?.subscription?.lastPayment
                        ?.purchaseId === null) && (
                      <>
                        <p className="desactivate-title">AUCUN ABONNEMENT</p>
                      </>
                    )}
                    {memberProfileFromStore?.account?.subscription?.lastPayment
                      ?.hasExpired === true &&
                      memberProfileFromStore?.account?.subscription?.lastPayment
                        ?.purchaseId !== null && (
                        <>
                          <p className="desactivate-title">
                            Abonnement <b>désactivé</b>
                          </p>
                          <div className="recap-part">
                            <div className="text-part">
                              <h3> MON DERNIER ABONNEMENT </h3>

                              <p className="desactivate">
                                {" "}
                                Depuis le &nbsp;
                                <b>
                                  {moment
                                    .default(paymentInfos?.startDate)
                                    .format("L")}
                                </b>
                              </p>
                              <p className="desactivate">
                                {`Jusqu'au`}
                                &nbsp;
                                <b>
                                  {moment
                                    .default(paymentInfos?.endDate)
                                    .format("L")}
                                </b>
                              </p>
                            </div>
                            <div className="infos-part">
                              <p>
                                {paymentInfos?.planName}
                                <br />
                                <b>
                                  {" "}
                                  {paymentInfos?.amount}
                                  {paymentInfos?.currency === "EUR" && "€"}
                                  {paymentInfos?.currency === "USD" && "$"} / 
                                </b>
                                {paymentInfos!?.countDuration +
                                  paymentInfos!?.duration ===
                                  "1DAY" && "Jour"}
                                {paymentInfos!?.countDuration +
                                  paymentInfos!?.duration ===
                                  "1MONTH" && "Mois"}
                                {paymentInfos!?.countDuration +
                                  paymentInfos!?.duration ===
                                  "3MONTH" && "Trimestre (03 mois)"}
                                {paymentInfos!?.countDuration +
                                  paymentInfos!?.duration ===
                                  "6MONTH" && "Semestre (06 mois)"}
                                {["1YEAR", "12MONTH"].includes(
                                  paymentInfos!?.countDuration +
                                    paymentInfos!?.duration
                                ) && "An (12 mois)"}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                  </Grid>
                )}

                {memberProfileFromStore?.account?.subscription?.lastPayment
                  ?.hasExpired !== false && (
                  <>
                    <Grid item xs={12} sm={12} lg={12} mt={2}>
                      <p>
                        <b>
                          {" "}
                          Choisissez une formule ci-dessous pour activer votre
                          compte
                        </b>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} justifyContent="center">
                      <FormControl
                        fullWidth
                        color="primary"
                        className="paiement-radio-row"
                      >
                        <RadioGroup
                          row
                          aria-labelledby="id-formule"
                          name="formule"
                          className="row-radio-buttons-group"
                          value={formikActivate.values.formule}
                          onChange={formikActivate.handleChange}
                        >
                          {loadingFormule ? (
                            <img
                              src={require("@/assets/images/gif/loadmore.gif")}
                              height="70"
                              width="70"
                              alt="load-more"
                            />
                          ) : formuleList.length > 0 ? (
                            formuleList.map((elt, index) => {
                              return (
                                <div
                                  key={index}
                                  className={classNames("formule-card", {
                                    active:
                                      formikActivate.values.formule === elt.id,
                                    error:
                                      formikActivate.touched.formule &&
                                      Boolean(formikActivate.errors.formule),
                                  })}
                                >
                                  <FormControlLabel
                                    value={elt.id}
                                    control={<Radio />}
                                    label={elt.name}
                                  />
                                  <span>
                                    {memberProfileFromStore?.infos?.identity
                                      ?.gender === "MALE"
                                      ? elt.price_for_men
                                      : elt.price_for_women}{" "}
                                    {elt?.currency === "EUR" && "€"}
                                    {elt?.currency === "USD" && "$"}
                                  </span>
                                  <small>
                                    {elt.duration === "1DAY" && "par jour"}
                                    {elt.duration === "1MONTH" && "par mois"}
                                    {elt.duration === "3MONTH" &&
                                      "par trimestre (03 mois)"}
                                    {elt.duration === "6MONTH" &&
                                      "par semestre (06 mois)"}
                                    {["1YEAR", "12MONTH"].includes(
                                      elt.duration
                                    ) && "par an (12 mois)"}
                                  </small>
                                </div>
                              );
                            })
                          ) : (
                            <>
                              <p> Aucun plan de paiement disponible.</p>
                            </>
                          )}
                        </RadioGroup>

                        <FormHelperText
                          error={
                            formikActivate.touched.formule &&
                            Boolean(formikActivate.errors.formule)
                          }
                        >
                          {formikActivate.errors.formule}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <LoadingButton
                        disabled={!formikActivate.isValid}
                        loading={loadingActivate}
                        loadingPosition="center"
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={
                          <InlineIcon icon="fluent:payment-32-regular" />
                        }
                      >
                        Procéder au paiement
                      </LoadingButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </form>
        )}
        {!didUserHasToPay && (
          <>
            {memberProfileFromStore?.account?.subscription?.isFree?.value ? (
              <>
                <div className="desactivate-box">
                  <Grid item xs={12} sm={12} lg={12} style={{ width: "100%" }}>
                    <div className="recap-part">
                      <div className="text-part">
                        <h3> ABONNEMENT GRATUIT</h3>
                        <p>
                          {" "}
                          Depuis le &nbsp;
                          <b>
                            {moment
                              .default(paymentInfos?.startDate)
                              .format("L")}
                          </b>
                        </p>
                        {false && (
                          <p>
                            {`Jusqu'au`}
                            &nbsp;
                            <b>
                              {moment
                                .default(paymentInfos?.endDate)
                                .format("L")}
                            </b>
                          </p>
                        )}
                      </div>
                      <div className="infos-part">
                        <p>
                          Plan gratuit
                          <br />
                          <b>0€ / </b>mois
                        </p>
                      </div>
                    </div>
                  </Grid>
                </div>
              </>
            ) : (
              <>
                {" "}
                <form
                  className="desactivate-box"
                  onSubmit={formik.handleSubmit}
                >
                  <Box>
                    <Grid container rowSpacing={4} columnSpacing={2}>
                      {loadingPayment ? (
                        <>
                          <img
                            src={require("@/assets/images/gif/loadmore.gif")}
                            height="70"
                            width="70"
                            alt="load-more"
                          />
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={12} lg={12}>
                            <div className="recap-part">
                              <div className="text-part">
                                <h3> MON ABONNEMENT EN COURS </h3>
                                <p>
                                  {" "}
                                  Depuis le &nbsp;
                                  <b>
                                    {moment
                                      .default(paymentInfos?.startDate)
                                      .format("L")}
                                  </b>
                                </p>
                                <p>
                                  {`Jusqu'au`}
                                  &nbsp;
                                  <b>
                                    {moment
                                      .default(paymentInfos?.endDate)
                                      .format("L")}
                                  </b>
                                </p>
                              </div>
                              <div className="infos-part">
                                <p>
                                  {paymentInfos?.planName}
                                  <br />
                                  <b>
                                    {" "}
                                    {paymentInfos?.amount}
                                    {paymentInfos?.currency === "EUR" && "€"}
                                    {paymentInfos?.currency === "USD" && "$"} / 
                                  </b>
                                  {paymentInfos!?.countDuration +
                                    paymentInfos!?.duration ===
                                    "1DAY" && "Jour"}
                                  {paymentInfos!?.countDuration +
                                    paymentInfos!?.duration ===
                                    "1MONTH" && "Mois"}
                                  {paymentInfos!?.countDuration +
                                    paymentInfos!?.duration ===
                                    "3MONTH" && "Trimestre (03 mois)"}
                                  {paymentInfos!?.countDuration +
                                    paymentInfos!?.duration ===
                                    "6MONTH" && "Semestre (06 mois)"}
                                  {["1YEAR", "12MONTH"].includes(
                                    paymentInfos!?.countDuration +
                                      paymentInfos!?.duration
                                  ) && "An (12 mois)"}
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} sm={12} lg={12} mt={2}>
                        <p>
                          <b>
                            {" "}
                            Pour quelle raison souhaitez-vous désactiver votre
                            abonnement ?{" "}
                          </b>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <FormControl fullWidth color="primary">
                          <RadioGroup
                            row
                            aria-labelledby="id-civilité"
                            className="radio-input"
                            name="reason_radio"
                            value={formik.values.reason_radio}
                            onChange={formik.handleChange}
                          >
                            <FormControlLabel
                              value="not-found-what-looking-for"
                              control={<Radio />}
                              label="Vous n'avez pas trouvé ce que vous recherchez"
                            />
                            <FormControlLabel
                              value="plateform-not-suitable"
                              control={<Radio />}
                              label="Le site ne vous convient pas"
                            />
                            <FormControlLabel
                              value="subscription-too-expensive"
                              control={<Radio />}
                              label="L'abonnement est trop cher"
                            />
                            <FormControlLabel
                              value="other-reason"
                              control={<Radio />}
                              label="Autre (préciser)"
                            />
                          </RadioGroup>
                          <FormHelperText
                            error={
                              formik.touched.reason_radio &&
                              Boolean(formik.errors.reason_radio)
                            }
                          >
                            {formik.errors.reason_radio}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {formik.values.reason_radio === "other-reason" && (
                        <Grid item xs={12} sm={12} lg={12}>
                          <TextField
                            placeholder="Autre raison"
                            variant="outlined"
                            label="Autre raison"
                            name="reason_other"
                            onChange={formik.handleChange}
                            value={formik.values.reason_other}
                            type="text"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} lg={12} mt={2}>
                        <p>
                          <b>
                            {" "}
                            Avez-vous une critique constructive à faire afin
                            d'améliorer la plate-forme ?{" "}
                          </b>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <div className="text-area">
                          <textarea
                            className="text-area-input"
                            rows={4}
                            name="amelioration"
                            onChange={formik.handleChange}
                            value={formik.values.amelioration}
                          ></textarea>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <LoadingButton
                          disabled={!formik.isValid}
                          loading={loading}
                          loadingPosition="center"
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Désactiver mon abonnement
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </>
            )}
          </>
        )}
      </PerfectScrollbar>

      {/* RESILIATION  DIALOG PART */}
      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Désactivation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Attention vous êtes sur le point de{" "}
            <b>supprimer votre abonnement</b>. Il vous faut un abonnement en
            cours de validité pour profiter de la plate-forme, si vous
            poursuivez votre accès sera désactivé, nous vous conseillons
            d'attendre <b>2 jours</b>&nbsp; avant le prochain renouvellement
            pour désactiver votre abonnement, si vous souhaitez quitter la
            plate-forme !
            <br />
            <br />
            <p className="spe">
              {" "}
              Êtes-vous sûr(e) de vouloir désactiver votre abonnement maintenant
              ?{" "}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogout}>Annuler</Button>
          <Button onClick={desactivate} autoFocus>
            Désactiver
          </Button>
        </DialogActions>
      </Dialog>
      {/* Ennd of RESILIATION  DIALOG PART */}

      {/* PAYMENT DIALOG PART   */}
      <Dialog
        open={openLogoutActivate}
        onClose={handleCloseLogoutActivate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontWeight: "bold",
            textTransform: "capitalize",
            color: "var(--ui-dark)",
          }}
        >
          {"Paiement abonnement"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>
              {" - "}
              {
                formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.name
              }
              {" - "}
            </b>
            <br />
            <p style={{ fontWeight: "500" }}>
              {
                formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.description
              }
            </p>
            <br />
            <p className="spe">
              Vous êtes sur le point de régler le montant de votre abonnement.
              Vous devrez payer la somme de &nbsp;
              <b>
                {memberProfileFromStore?.infos?.identity?.gender === "MALE"
                  ? formuleList.find(
                      (x) => x.id === formikActivate.values.formule
                    )?.price_for_men
                  : formuleList.find(
                      (x) => x.id === formikActivate.values.formule
                    )?.price_for_women}{" "}
                €
              </b>{" "}
              valable pour une période de &nbsp;
              <b>
                {formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.duration === "1MONTH" &&
                  "01 mois ( renouvelable automatiquement )"}
                {formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.duration === "3MONTH" &&
                  "03 mois ( renouvelable automatiquement sauf via paypal )"}
                {formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.duration === "6MONTH" &&
                  "06 mois ( renouvelable automatiquement sauf via paypal )"}
                {formuleList.find((x) => x.id === formikActivate.values.formule)
                  ?.duration === "12MONTH" &&
                  "01 an (12mois) ( renouvelable automatiquement sauf via paypal )"}
              </b>
            </p>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              className="nav-part__btn-nav"
              style={{ color: "var(--ui-light)", fontWeight: "bold" }}
              onClick={() => {
                activateAccountSubmit(formikActivate.values.formule);
                handleCloseLogoutActivate();
              }}
            >
              Payer par carte bancaire&nbsp;&nbsp;
              <img
                src={require("@/assets/images/png/pay-by-cb.png")}
                alt="pay-by-cb"
                width={50}
              />
            </Button>
            <br />
            <br />
            <Button
              variant="contained"
              color="tertiary"
              className="nav-part__btn-nav"
              style={{ color: "var(--ui-primary)", fontWeight: "bold" }}
              onClick={() => {
                paypalInitPayment(formikActivate.values.formule);
                handleCloseLogoutActivate();
              }}
            >
              Payer par&nbsp;&nbsp;
              <img
                src={require("@/assets/images/png/paypal.png")}
                alt="paypal"
                width={50}
              />
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutActivate}>Fermer</Button>
        </DialogActions>
      </Dialog>
      {/* Ennd of PAYMENT  DIALOG PART */}
    </>
  );
};

export default DesactivateSubscription;
