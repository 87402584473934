import { routePaths } from "@/config";
import { Navigate, Route, Routes } from "react-router-dom";
import MyAccount from "../../MyAccount.page";
import Fiche from "../components/Fiches/Fiche";
import Formulaire from "../components/Formulaire/Formulaire";


export const MyAccountRoutes:React.FC = () => {
    return (
      <Routes>
          <Route path="" element={<MyAccount />}>
              <Route index element={ <Navigate to={`${routePaths.profileChildren.myAccountChildren.formAbsolute}`} /> } />
              <Route path={routePaths.profileChildren.myAccountChildren.fiche} element={ <Fiche />} />
              <Route path={routePaths.profileChildren.myAccountChildren.form} element={ <Formulaire />} />
              <Route path="/*" element={ <Navigate to={`${routePaths.profileChildren.myAccountChildren.formAbsolute}`} />} />
          </Route>
      </Routes>
    );
};