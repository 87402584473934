import React from 'react';
import Header from '../containers/Header/Header.container';
import CtSubscription from './Subscription-content /Subscription.content';

const Subscription:React.FC = () => {
  return (
    <>
      <Header 
        title="GESTION DE MON ABONNEMENT"
        subtitle="" />
      <CtSubscription />
    </>
  )
}

export default Subscription;