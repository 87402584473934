import React from 'react'
import ForgottenPasswordForm from '../forms/ForgottenPasswordForm/ForgottenPasswordForm'

const ForgottenPassword: React.FC = () => {
  return (
    <>
      <ForgottenPasswordForm />
    </>
  )
}

export default ForgottenPassword;
