import React from 'react';
import { Outlet } from 'react-router-dom';
import "./Subscription.styles.scss";

const CtSubscription:React.FC = () => {



  return (
    <div className='ct-subscription'>
        <Outlet/>
    </div>
  )
}

export default CtSubscription