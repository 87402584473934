import { InlineIcon } from "@iconify/react";
import {
  Badge,
  Button,
  Grow,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./Home.styles.scss";
import NoData from "@/components/No-data/NoData";
import ProfileCard, {
  TProfileCard,
} from "../../Elements/ProfileCard/ProfileCard";
import { apiGetMember } from "../../api/profile.api";
import { selectSearchQuery } from "@/store/reducers/general_param/general_param.selector";
import { useSelector } from "react-redux";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalResearch from "@/components/Modal-research/ModalResearch";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";
import { calculerAge } from "@/utils/calculerAge";
import { TPaginationResult } from "../../types";
import { TMemberProfile } from "@/features/auth/types";
import {
  selectMemberProfile,
  selectMessageNotif,
} from "@/store/reducers/member/member.selector";
import { getMeUpdate } from "../../initialize_param/get_me_update";
import useExchangeRole from "@/hooks/useExchangeRole";
import { store } from "@/store";
import { updateOpenDiscussion } from "@/store/reducers/general_param/general_param.actions";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";

export type TProfileList = Array<TProfileCard>;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}));
const CtHome = () => {
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));
  const nbre_message_notif = useSelector(selectMessageNotif);
  const navigate = useNavigate();
  const { receiver } = useExchangeRole();

  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const LIMIT_MEMBER = 100;

  const search_query = useSelector(selectSearchQuery);

  const {
    isUserFullAccessAutorized,
    isUserInExchange,
    isUserCloseExchangeInProgress,
  } = useUserRestrictions();

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const handleOpenSearchModal = () => setOpenSearchModal(true);
  const handleCloseSearchModal = () => setOpenSearchModal(false);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const scrollRef = useRef<any>();
  const [paginationResult, setPaginationResult] =
    useState<TPaginationResult | null>(null);

  const [searchedText, setSearchedText] = useState("");

  const [membersList, setMembersList] = useState<TProfileList>([]);

  const getFilteredMember = useCallback(
    (page: number, searchValue: -1 | 0, queryFromSearch: string) => {
      if (isUserInExchange || isUserCloseExchangeInProgress) {
      } else {
        if (isUserFullAccessAutorized) {
          setLoading(true);
          apiGetMember(
            page,
            LIMIT_MEMBER,
            queryFromSearch +
              `${searchValue === -1 ? "" : searchedText === "" ? "" : "&identifier=" + searchedText}`
          )
            .then((response: any) => {
              setLoading(false);
              console.log(response);

              let tempList: TProfileList;
              tempList = response.data.result.map((data: any) => {
                return {
                  _id: data._id,
                  isVerified: data.status === "VALIDATED",
                  username: data.username,
                  type: "",
                  progressValue: Math.round(data.rateOfCompletion),
                  profil_id: data.memberId,
                  isOnline: data?.online,
                  age: calculerAge(new Date(data.birthDate)),
                  town: data.city,
                  country: data.country,
                  origine: data.homeCountry,
                  nationalite: data.citizenship,
                  hasMore: false,
                  sendStatut: "",
                  description: data.description,
                  profile_wanted: data.profilewanted,
                  currentConversation: data.currentConversation,
                  date_of_creation: new Date(data.registrationDate),
                  last_connection:
                    data.lastLogin !== null ? new Date(data.lastLogin) : null,
                  isSimpleFavorite: data.isFavorite,
                  isMutualFavorite: false,
                  isVisited: data.isVisited,
                  isVisitor: data.isVisitor,
                  isListeNoire: false,
                  is_banished: false,
                  gender:
                    memberProfileFromStore.infos.identity.gender === "MALE"
                      ? "FEMALE"
                      : "MALE",
                };
              });

              console.log([...tempList]);
              setPaginationResult(response.data.paginationResult);
              setMembersList((prevData) => [...prevData, ...tempList]);
            })
            .catch((error) => {
              console.log("ERROR", error);
              setLoading(false);
              setMembersList([]);
              setPage(1);
              //console.log(error);
              // error?.response?.data?.message &&
              //   Toastify(`${error?.response?.data?.message}`, "error", 5000);
            });
        }
      }
    },
    [
      isUserInExchange,
      isUserCloseExchangeInProgress,
      isUserFullAccessAutorized,
      searchedText,
      memberProfileFromStore.infos.identity.gender,
    ]
  );

  const handleSearchChange = useCallback(
    (e: any) => {
      console.log(e?.target?.value);
      setSearchedText(e?.target?.value);
      setPage(1);

      if (e?.target?.value === "") {
        getFilteredMember(1, -1, search_query);
      }
    },
    [getFilteredMember, search_query]
  );

  const clearClick = useCallback(() => {
    setPage(1);
    setSearchedText("");
    setMembersList([]);
    getFilteredMember(1, -1, search_query);
  }, [getFilteredMember, search_query]);

  const searchClick = useCallback(() => {
    setPage(1);
    setMembersList([]);
    getFilteredMember(1, 0, search_query);
  }, [getFilteredMember, search_query]);

  useEffect(() => {
    getFilteredMember(1, 0, search_query);
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUserFullAccessAutorized,
    isUserCloseExchangeInProgress,
    isUserInExchange,
  ]);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
        if (paginationResult?.nextPage === null) {
        } else {
          const nextPage = page + 1;
          setPage(nextPage);
          getFilteredMember(nextPage, 0, search_query);
        }
      }
    }
  }, [
    getFilteredMember,
    search_query,
    loading,
    page,
    paginationResult?.nextPage,
  ]);

  useEffect(() => {
    const container = scrollRef.current;

    container && container.addEventListener("scroll", handleScroll);

    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading, page, scrollRef]);

  const updateMember = useCallback(
    (
      id: string,
      type?:
        | "add-favorite"
        | "remove-favorite"
        | "add-blacklist"
        | "remove-blacklist"
        | "visited"
    ) => {
      const findIndex = membersList.findIndex((elt) => elt._id === id);
      if (findIndex !== -1) {
        const updatedMember = {
          ...membersList[findIndex],
        };
        if (type === "add-favorite") {
          updatedMember.isSimpleFavorite = true;
        }
        if (type === "remove-favorite") {
          updatedMember.isSimpleFavorite = false;
        }
        if (type === "add-blacklist") {
          updatedMember.isListeNoire = true;
        }
        if (type === "remove-blacklist") {
          updatedMember.isListeNoire = false;
        }
        if (type === "visited") {
          updatedMember.isVisited = true;
        }

        setMembersList(
          membersList.map((elt) => (elt._id === id ? updatedMember : elt))
        );
      }
    },
    [membersList]
  );

  useEffect(() => {
    getMeUpdate();
  }, []);

  return (
    <>
      {isUserFullAccessAutorized ? (
        <div className="ct-home">
          {!(isUserInExchange || isUserCloseExchangeInProgress) && (
            <>
              <div className="ct-home__filter-part">
                <TextField
                  placeholder="pseudo/id"
                  variant="outlined"
                  name="recherche"
                  className="searchInput"
                  type="text"
                  value={searchedText}
                  onChange={handleSearchChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchClick();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Grow
                          in={searchedText === ""}
                          mountOnEnter
                          unmountOnExit
                        >
                          <div>
                            <InlineIcon icon="bi:search" />
                          </div>
                        </Grow>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Grow
                          in={searchedText !== ""}
                          mountOnEnter
                          unmountOnExit
                        >
                          <div>
                            <IconButton
                              className="sm-btn"
                              size="medium"
                              onClick={clearClick}
                            >
                              <InlineIcon
                                icon="mdi:erase"
                                color="var(--ui-medium)"
                              />
                            </IconButton>
                          </div>
                        </Grow>
                      </InputAdornment>
                    ),
                  }}
                />

                <Grow in={searchedText !== ""} mountOnEnter unmountOnExit>
                  <div>
                    <IconButton
                      style={{
                        background: "var(--ui-primary)",
                      }}
                      className="sm-btn"
                      size="medium"
                      color="secondary"
                      onClick={searchClick}
                    >
                      <InlineIcon icon="icon-park-twotone:search" />
                    </IconButton>
                  </div>
                </Grow>

                {matches_sm ? (
                  <>
                    <StyledBadge
                      overlap="rectangular"
                      variant="dot"
                      color="warning"
                      badgeContent={search_query !== "" ? 1 : 0}
                    >
                      <IconButton
                        style={{ background: "var(--ui-tertiary)" }}
                        className="sm-btn"
                        size="medium"
                        color="secondary"
                        onClick={handleOpenSearchModal}
                      >
                        <InlineIcon
                          icon="ic:round-filter-alt"
                          color="var(--ui-primary)"
                        />
                      </IconButton>
                    </StyledBadge>
                  </>
                ) : (
                  <>
                    <StyledBadge
                      overlap="rectangular"
                      variant="dot"
                      color="warning"
                      badgeContent={search_query !== "" ? 1 : 0}
                    >
                      <Button
                        style={{
                          color: "var(--ui-primary)",
                        }}
                        variant="contained"
                        color="tertiary"
                        className="btn"
                        onClick={handleOpenSearchModal}
                        startIcon={
                          <InlineIcon
                            icon="ic:round-filter-alt"
                            style={{ fontSize: "18px" }}
                          />
                        }
                      >
                        Filtrer
                      </Button>
                    </StyledBadge>
                  </>
                )}
              </div>
              <div className="result">
                <p>
                  {search_query !== "" && (
                    <>
                      <span>&nbsp; Cette liste est filtrée &nbsp;•&nbsp; </span>
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={handleOpenSearchModal}
                      >
                        {" "}
                        Modifier les critères{" "}
                      </b>
                    </>
                  )}
                </p>
              </div>
            </>
          )}

          <div ref={scrollRef} className="ct-home__body-part">
            {
              <>
                {(isUserInExchange ||
                  isUserCloseExchangeInProgress ||
                  membersList.length === 0) &&
                  !loading && (
                    <>
                      <NoData />
                      {isUserInExchange && (
                        <p
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.2rem",
                          }}
                        >
                          {" "}
                          Vous êtes actuellement en <b>échange</b>
                          {receiver?.username && (
                            <>
                              {" "}
                              avec{" "}
                              <b style={{ color: "var(--ui-primary)" }}>
                                {receiver?.username}
                              </b>
                            </>
                          )}
                          . <br />
                          Vous ne pouvez pas avoir accès aux autres profils{" "}
                          <br />
                          <br />
                          <Button
                            variant="contained"
                            onClick={() => {
                              store.dispatch(updateOpenDiscussion(true));
                              navigate(
                                routePaths.profileChildren.exchangeAbsolute,
                                {
                                  replace: true,
                                }
                              );
                            }}
                            startIcon={
                              <InlineIcon icon="bx:message-rounded-detail" />
                            }
                            color="primary"
                            style={{
                              width: "fit-content",
                              fontSize: "1rem",
                              padding: "10px 20px",
                            }}
                          >
                            <>Accéder à l'échange</>
                            {nbre_message_notif > 0 && (
                              <>
                                    
                                <StyledBadge
                                  overlap="circular"
                                  variant="standard"
                                  color="warning"
                                  badgeContent={nbre_message_notif}
                                />
                              </>
                            )}
                          </Button>
                        </p>
                      )}
                      {isUserCloseExchangeInProgress && (
                        <p
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontSize: "1.2rem",
                          }}
                        >
                          {" "}
                          Votre clôture d'échange est actuellement en{" "}
                          <b>examination</b> auprès de nos modérateurs. <br />
                          Vous ne pouvez pas avoir accès aux autres profils{" "}
                        </p>
                      )}
                    </>
                  )}
                {membersList.length > 0 &&
                  !(isUserInExchange || isUserCloseExchangeInProgress) && (
                    <>
                      <div className="profile-card-grid-container">
                        {membersList
                          .filter((x) => x.isListeNoire === false)
                          .map((value, index) => {
                            return (
                              <ProfileCard
                                key={index}
                                _id={value._id}
                                isOnline={value.isOnline}
                                isVerified={value.isVerified}
                                hasMore={value.hasMore}
                                username={value.username}
                                description={value.description}
                                profile_wanted={value.profile_wanted}
                                type={value.type}
                                age={value.age}
                                town={value.town}
                                country={value.country}
                                origine={value.origine}
                                profil_id={value.profil_id}
                                nationalite={value.nationalite}
                                progressValue={value.progressValue}
                                sendStatut={value.sendStatut}
                                currentConversation={value.currentConversation}
                                date_of_creation={value.date_of_creation}
                                last_connection={value.last_connection}
                                isSimpleFavorite={value.isSimpleFavorite}
                                isMutualFavorite={value.isMutualFavorite}
                                isVisited={value.isVisited}
                                isVisitor={value.isVisitor}
                                gender={value.gender}
                                refreshHome={(
                                  type?:
                                    | "add-favorite"
                                    | "remove-favorite"
                                    | "add-blacklist"
                                    | "remove-blacklist"
                                    | "visited"
                                ) => {
                                  updateMember(value._id || "", type);
                                }}
                                is_banished={value.is_banished}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}

                {loading && (
                  <img
                    src={require("@/assets/images/gif/loadmore.gif")}
                    height="70"
                    width="70"
                    className="load-more-home"
                    alt="load-more"
                  />
                )}
              </>
            }
          </div>
        </div>
      ) : (
        <>
          <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
            <div className="ct-home">
              <UserRestrictionDisplayer />
            </div>
          </Grow>
        </>
      )}
      <ModalResearch
        loadingFromHome={loading}
        open={openSearchModal}
        applyFilter={(query: string) => {
          setPage(1);
          setMembersList([]);
          getFilteredMember(1, -1, query);
          if (matches_xl) {
            handleCloseSearchModal();
          }
        }}
        onClose={handleCloseSearchModal}
        closeClick={handleCloseSearchModal}
      />
      {false && <ModalUserRestrictionWhenPaid />}
    </>
  );
};

export default CtHome;
