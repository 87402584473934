import React from 'react';
import Header from '../containers/Header/Header.container';
import CtBlacklist from './Blacklist-content/Blacklist.content';

const Blacklist:React.FC = () => {
  return (
    <>
      <Header 
        title="MA LISTE NOIRE"
        subtitle="LES PROFILS BLOQUÉS" />
      <CtBlacklist/>
    </>
  )
}

export default Blacklist;