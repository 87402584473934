import { TSignUp } from "@/features/auth/types";
import { TProfileCard } from "@/features/profile/Elements/ProfileCard/ProfileCard";
import temp_dataTypes from "./temp_data.types";

interface IUpdateSignUp{
  type: temp_dataTypes.UPDATE_SIGNUP;
  payload: TSignUp;
}

interface IUpdateEmail{
  type: temp_dataTypes.UPDATE_EMAIL;
  payload: string;
}

interface IUpdateListFavoris{
  type: temp_dataTypes.UPDATE_LIST_FAVORIS;
  payload: TProfileCard[];
}

interface IUpdateListFans{
  type: temp_dataTypes.UPDATE_LIST_FANS;
  payload: TProfileCard[];
}

interface IUpdateBlackList{
  type: temp_dataTypes.UPDATE_BLACK_LIST;
  payload: TProfileCard[];
}

interface IUpdateDemandList{
  type: temp_dataTypes.UPDATE_DEMAND_LIST;
  payload: TProfileCard[];
}


export type TempDataAction =  IUpdateSignUp | 
                              IUpdateEmail |
                              IUpdateListFavoris |
                              IUpdateListFans |
                              IUpdateBlackList |
                              IUpdateDemandList;


export const updateSignUp = ( sign_up : TSignUp ) => ({
  type: temp_dataTypes.UPDATE_SIGNUP,
  payload: sign_up
});

export const updateEmail = ( email : string ) => ({
  type: temp_dataTypes.UPDATE_EMAIL,
  payload: email
});

export const updateListFavoris = ( favorisList : TProfileCard[] ) => ({
  type: temp_dataTypes.UPDATE_LIST_FAVORIS,
  payload: favorisList
});

export const updateListFans = ( fansList : TProfileCard[] ) => ({
  type: temp_dataTypes.UPDATE_LIST_FANS,
  payload: fansList
});

export const updateBlackList = ( blackList : TProfileCard[] ) => ({
  type: temp_dataTypes.UPDATE_BLACK_LIST,
  payload: blackList
});

export const updateDemandList = ( demandList : TProfileCard[] ) => ({
  type: temp_dataTypes.UPDATE_DEMAND_LIST,
  payload: demandList
});
