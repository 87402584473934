import React from 'react';
import Header from '../containers/Header/Header.container';
import CtQuestions from './Questions-content /Questions.content';

const Questions:React.FC = () => {
  return (
    <>
      <Header 
        title="QUESTIONS"
        subtitle=" " />
      <CtQuestions />
    </>
  )
}

export default Questions;