import React from 'react';
import Header from '../containers/Header/Header.container';
import CtExchange from './Exchange-content/Exchange.content';

const Exchange:React.FC = () => {
  return (
    <>
      <Header 
        title="MES ECHANGES"
        subtitle="CONSULTEZ VOS MESSAGES" />
      <CtExchange />
    </>
  )
}

export default Exchange;