import { apiGetUserConnected } from "@/features/auth/api/auth.api";
import { TMemberProfile } from "@/features/auth/types";
import { store } from "@/store";
import {
  getMemberInfos,
  updateMemberProfile,
  updateMemberCurrentExchange,
  updateIsOnline,
} from "@/store/reducers/member/member.actions";
import { TMemberInfos } from "../types";

export const getMeUpdate = () => {
  apiGetUserConnected()
    .then((response: any) => {
      const profile = response.data as TMemberProfile;
      console.log({ profile });
      const infosUser: TMemberInfos = {
        _id: profile.member._id,
        email: profile.member.email,
        role: profile.member.role,
        username: profile.member.username,
        memberId: profile.member.memberId,
        isVerified: store.getState().member.member_infos!?.isVerified,
        isInEmailVerificationProcess:
          store.getState().member.member_infos!?.isInEmailVerificationProcess,
      };
      console.log({ infosUser });
      store.dispatch(getMemberInfos({ ...infosUser }));
      store.dispatch(updateMemberProfile({ ...profile }));
      store.dispatch(
        updateMemberCurrentExchange(profile?.currentConversation || null)
      );
      store.dispatch(updateIsOnline(profile.availability.isAvailable));
    })
    .catch((error) => {
      console.log("😜", error);
    });
};
