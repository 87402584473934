import { axios } from "@/lib/axios";
import { store } from "@/store";
import {
  updateEthnies,
  updateGeneralLinks,
  updateMailFemale,
  updateMailMale,
  updateMaxCaracter,
  updateMaxMessage,
  updateModeration,
  updateIsModerationSuspend,
  updateMessageClosedModeration,
  updateIsWebsiteMaintenance,
  updateBodyshape,
} from "@/store/reducers/general_param/general_param.actions";
import {
  getMemberInfos,
  updateIsOnline,
  updateMemberCurrentExchange,
  updateMemberProfile,
} from "@/store/reducers/member/member.actions";
import { Toastify } from "@/utils/toast";
import { apiGetInterfaceSettings } from "../api/general.api";
import { TInterfaceSettings, TMemberInfos, TModerationSocket } from "../types";
import {
  apiGetModerationSchedule,
  apiGetUserConnected,
} from "@/features/auth/api/auth.api";
import { TMemberProfile } from "@/features/auth/types";

export const initializeParam = () => {
  axios
    .get("/param-s/api/v1/parameters/fatness")
    .then((response: any) => {
      console.log({ response });
      const data = response.data.result.map((x: any) => ({
        id: x._id,
        name: x.name,
      }));
      console.log(data);
      store.dispatch(updateBodyshape([...data]));
    })
    .catch((error: any) => {
      console.log(error);
      console.log(error);
    });

  axios
    .get("/param-s/api/v1/parameters/ethnicities")
    .then((response: any) => {
      console.log({ response });
      const data = response.data.result.map((x: any) => ({
        id: x._id,
        name: x.name,
      }));
      console.log(data);
      store.dispatch(updateEthnies([...data]));
    })
    .catch((error: any) => {
      console.log(error);
      console.log(error);
      // error?.response?.data?.message &&
      //   Toastify(`${error?.response?.data?.message}`, "error", 5000);
    });

  //store.dispatch(updateMaxMessage(10)); // <- à enlever ici après
  //store.dispatch(updateNbreMessageSent(0)); // <- à enlever ici après
  //store.dispatch(updateNbreMessageReceive(0)); // <- à enlever ici après

  apiGetInterfaceSettings()
    .then((response: any) => {
      console.log("PARAMÈTRE 🖱️", response);
      const interfaceSetting: TInterfaceSettings =
        response.data as TInterfaceSettings;

      store.dispatch(
        updateMaxMessage(interfaceSetting.moderation.numberMessagePerDiscussion)
      );

      store.dispatch(
        updateMaxCaracter(interfaceSetting.moderation.numberCaractersPerMessage)
      );

      store.dispatch(updateMailMale(interfaceSetting.menGroupHeader.email));

      store.dispatch(updateMailFemale(interfaceSetting.womenGroupHeader.email));

      store.dispatch(
        updateGeneralLinks({
          youtube: interfaceSetting.socialMedia.youtube,
          telegram: interfaceSetting.socialMedia.telegram,
          facebook: interfaceSetting.socialMedia.facebook,
          twitter: interfaceSetting.socialMedia.twitter,
          instagram: interfaceSetting.socialMedia.instagram,
        })
      );

      store.dispatch(
        updateIsModerationSuspend(
          interfaceSetting.maintenance.suspendModeration
        )
      );

      store.dispatch(
        updateIsWebsiteMaintenance(interfaceSetting.maintenance.website)
      );

      store.dispatch(
        updateMessageClosedModeration(
          interfaceSetting?.moderation?.schedule || ""
        )
      );
    })
    .catch((error: any) => {
      console.log(error);
      //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
    });

  apiGetUserConnected()
    .then((response: any) => {
      const profile = response.data as TMemberProfile;
      console.log({ profile });
      const infosUser: TMemberInfos = {
        _id: profile.member._id,
        email: profile.member.email,
        role: profile.member.role,
        username: profile.member.username,
        memberId: profile.member.memberId,
        isVerified: store.getState().member.member_infos!?.isVerified,
        isInEmailVerificationProcess:
          store.getState().member.member_infos!?.isInEmailVerificationProcess,
      };
      console.log({ infosUser });
      store.dispatch(getMemberInfos({ ...infosUser }));
      store.dispatch(updateMemberProfile({ ...profile }));
      store.dispatch(
        updateMemberCurrentExchange(profile?.currentConversation || null)
      );
      store.dispatch(updateIsOnline(profile.availability.isAvailable));
    })
    .catch((error) => {
      console.log("😜", error);
    });

  false &&
    apiGetModerationSchedule()
      .then((response: any) => {
        console.log(response);

        const data: TModerationSocket = {
          day: (response as TModerationSocket).day,
          end_time: (response as TModerationSocket).end_time,
          is_holiday: (response as TModerationSocket).is_holiday,
          message: (response as TModerationSocket).message,
          start_time: (response as TModerationSocket).start_time,
          status: (response as TModerationSocket).status,
        };
        store.dispatch(updateModeration(data));
      })
      .catch((error) => {
        console.log("😜", error);
      });
};
