import LContainer from '@/components/Layouts/Container/Container.layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import logo from "@/assets/images/png/logo.png";
import './Authentication.styles.scss';

const LAuthentication: React.FC = () => {

  return (
    <div className="authentication-layout-container">
        <LContainer>
            <div 
              className="form-container">
              <img src={logo} alt="" className="logo-title" />
              <Outlet />
            </div>
        </LContainer>
    </div>
  )
}

export default LAuthentication;
