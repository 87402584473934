import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectGeneralParam = (state: TRootState) => state.general_param;

export const selectEthnies = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.ethnies
);

export const selectBodyshape = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.bodyshape
);

export const selectSearchQuery = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.search_query
);

export const selectFormQuery = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.form_query
);

export const selectOpenDiscussion = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.openDiscussion
);

export const selectCurrentExchange = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.current_exchange
);

export const selectMaxMessage = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.max_message
);

export const selectNbreMessageSent = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.nbre_message_sent
);

export const selectNbreMessageReceive = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.nbre_message_receive
);

export const selectModerationSocket = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.moderation_socket
);

export const selectNbreNotificationSocket = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.nbre_notification_socket
);

export const selectNbreLiveSocket = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.nbre_live_socket
);

export const selectMaxCaracter = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.max_caracter
);

export const selectMailMale = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.mail_male
);

export const selectMailFemale = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.mail_female
);

export const selectGeneralLinks = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.general_links
);

export const selectIsModerationSuspend = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.is_moderation_suspend
);

export const selectMessageClosedModeration = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.message_closed_moderation
);

export const selectTourStepIsOpen = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.tour_step_is_open
);

export const selectIsWebsiteMaintenance = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.is_website_maintenance
);

export const selectIsPaymentPendingProcess = createSelector(
  [selectGeneralParam],
  (general_param) => general_param.is_payment_pending_process
);
