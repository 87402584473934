import React, { ErrorInfo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import HomeIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Toastify, ToastSuccess } from "@/utils/toast";
import logo from "@/assets/images/png/logo.png";
import logoMini from "@/assets/images/png/logo-mini.png";
import {
  YouTube,
  Send,
  FacebookRounded,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { InlineIcon } from "@iconify/react";
import "./SafeErrorBoundaries.styles.scss";
import { routePaths } from "@/config";
import { store, persistor } from "@/store";
import { getToken } from "@/store/reducers/member/member.actions";
import { axios } from "@/lib/axios";

const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const goToHome = () => {
    navigate(routePaths.profileChildren.homeAbsolute, { replace: true });
    window.location.reload();
  };

  console.log({ error });

  const sendReport = () => {
    console.log("error", error);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);

      const message = `${error.message}\n\n${error.stack}`;
      const mail = "landryyamb446@gmail.com";
      const subject = "ERREUR-SIGNALIEE FRONTOFFICE";

      const mailtoLink = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;

      Toastify("L'erreur a bien  été signalée", "success", 3000);
      goToHome();
    }, 2000);
  };

  const logout = () => {
    axios.post("/auth-s/api/v1/auth/logout").finally(() => {
      navigate(routePaths.auth, { replace: true });
      store.dispatch({ type: "CLEAR_STORE" });
      localStorage.clear();
      store.dispatch(getToken(""));
      persistor.purge();
      window.location.reload();
    });
  };

  return (
    <div className="error-boundary-container">
      <div className="top">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="middle">
        <img src={logoMini} alt="" className="logo-mini" />
        <p className="oups">OUPS</p>
        <p className="title-head"> Une erreur inattendue s'est produite </p>
        {/* <p className="body-text">
          Si le problème persiste, n'hésitez pas à le signaler.
        </p> */}
        <div className="btn-layout">
          <Button
            variant="contained"
            color="primary"
            onClick={goToHome}
            startIcon={<InlineIcon icon="bx:home" />}
            className="btn-home"
          >
            Revenir à l'accueil
          </Button>
          <p style={{ width: "100%", textAlign: "center", marginTop: "10px" }}>
            {" "}
            Le retour à l'accueil ne fonctionne pas? <br />
            <b
              style={{ cursor: "pointer", color: "var(--ui-primary)" }}
              onClick={logout}
            >
              {" "}
              Se déconnecter
            </b>
          </p>

          {false && (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="warning"
              onClick={sendReport}
              startIcon={<InlineIcon icon="tabler:send" />}
              className="btn-send-report"
            >
              Signaler l'erreur{" "}
            </LoadingButton>
          )}
        </div>
        <p style={{ textAlign: "center", width: "100%", marginTop: "auto" }}>
          Pour toute(s) autre(s) préoccupation(s) contactez-nous par e-mail
          <br />
          <b>
            <a href="mailto:zawaj.sounnah@gmail.com">zawaj.sounnah@gmail.com</a>
          </b>
        </p>
      </div>
      <div className="bottom">
        <div className="copyright">
          <p>
            © 2024 
            <a href="https://zawaj-sounnah.com/">Zawaj Sounnah</a>.<br />
            <small>Une cause pour le mariage</small>
          </p>
        </div>
        <div className="social">
          <a
            className="rounded-square"
            href="https://www.youtube.com/@zawajsounnah2059"
            target="_blank"
            rel="noreferrer"
          >
            <YouTube color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://t.me/zawajsounnah"
            target="_blank"
            rel="noreferrer"
          >
            <Send color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.facebook.com/zawajsounnah/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookRounded color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://twitter.com/ZawajSounnah?t=E5cbH2yqGe6ws_X_ZIOVxQ&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.instagram.com/zawaj.sounnah/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram color="primary" />
          </a>
        </div>
      </div>
    </div>
  );
};

const myErrorHandler = (error: Error, info: ErrorInfo) => {
  //console.log('error',error);
  //console.log('info',info);
};

export const SafeErrorBoundaries: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary onError={myErrorHandler} FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};
