import { TRootState } from "@/store";
import { createSelector } from "reselect";

export const selectTempData = (state: TRootState) => state.temp_data;

export const selectSignUp= createSelector(
  [selectTempData],
  (temp_data) => temp_data.sign_up
);

export const selectEmail= createSelector(
  [selectTempData],
  (temp_data) => temp_data.email
);

export const selectFavorisList= createSelector(
  [selectTempData],
  (temp_data) => temp_data.favorisList
);

export const selectFansList= createSelector(
  [selectTempData],
  (temp_data) => temp_data.fansList
);

export const selectBlackList= createSelector(
  [selectTempData],
  (temp_data) => temp_data.blackList
);

export const selectDemandList= createSelector(
  [selectTempData],
  (temp_data) => temp_data.demandLisk
);
