import { TMemberProfile } from "@/features/auth/types";
import { TMemberInfos } from "@/features/profile/types";
import {
  selectMemberCurrentExchange,
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const useUserRestrictions = () => {
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;
  const memberCurrentExchangeFromStore = useSelector(
    selectMemberCurrentExchange
  );

  const isUserEmailVerified = useCallback((): boolean => {
    if (memberInfosFromStore.isVerified) {
      return true;
    } else {
      if (memberProfileFromStore.member.isEmailVerified) {
        return true;
      } else {
        return false;
      }
    }
  }, [
    memberInfosFromStore.isVerified,
    memberProfileFromStore.member.isEmailVerified,
  ]);

  const isUserEmailVerificationExpired = useCallback((): boolean => {
    return (
      !isUserEmailVerified() &&
      !memberInfosFromStore.isInEmailVerificationProcess
    );
  }, [isUserEmailVerified, memberInfosFromStore.isInEmailVerificationProcess]);

  // Lorsque les modifications des utilisateurs sont approuvés
  const isUserProfileModificationApproved = useCallback((): boolean => {
    if (memberProfileFromStore?.status?.value === "NEW") {
      return true;
    } else {
      return memberProfileFromStore?.status?.value === "VALIDATED";
    }
  }, [memberProfileFromStore?.status?.value]);

  // Lorsque les modifications des utilisateurs sont rejetés
  const isUserProfileModificationRejected = useCallback((): boolean => {
    return memberProfileFromStore?.status?.value === "REJECTED";
  }, [memberProfileFromStore?.status?.value]);

  // Lorsque les modifications des utilisateurs sont en attente de validation
  const isUserProfileModificationPending = useCallback((): boolean => {
    if (
      memberProfileFromStore?.status?.value === "VALIDATED" &&
      memberProfileFromStore?.account?.subscription?.lastPayment?.hasExpired !==
        false &&
      memberProfileFromStore?.approbation?.isApproved !== true
    ) {
      return true;
    } else {
      return (
        memberProfileFromStore?.status?.value === "VALIDATED_EDITED" ||
        memberProfileFromStore?.status?.value === "REJECTED_EDITED" ||
        memberProfileFromStore?.status?.value === "NEW_EDITED"
      );
    }
  }, [
    memberProfileFromStore?.account?.subscription?.lastPayment?.hasExpired,
    memberProfileFromStore?.approbation?.isApproved,
    memberProfileFromStore?.status?.value,
  ]);

  // Lorsque l'ulisateur doit payer
  const didUserHasToPay = useCallback((): boolean => {
    if (memberProfileFromStore?.account?.subscription?.isFree?.value) {
      return false;
    } else if (!memberProfileFromStore?.activation?.isActivate) {
      return false;
    } else {
      return (
        [
          "VALIDATED",
          "VALIDATED_EDITED",
          "REJECTED",
          "REJECTED_EDITED",
        ].includes(memberProfileFromStore?.status?.value) &&
        memberProfileFromStore?.account?.subscription?.lastPayment
          ?.hasExpired !== false &&
        memberProfileFromStore?.approbation?.isApproved === true
      );
    }
  }, [
    memberProfileFromStore?.status?.value,
    memberProfileFromStore?.account?.subscription?.lastPayment?.hasExpired,
    memberProfileFromStore?.account?.subscription?.isFree?.value,
    memberProfileFromStore?.approbation?.isApproved,
    memberProfileFromStore?.activation?.isActivate,
  ]);

  const isUserAccountBlockedByAdmin = useCallback((): boolean => {
    return !memberProfileFromStore?.activation?.isActivate;
  }, [memberProfileFromStore?.activation?.isActivate]);

  // isUserFullAccessAutorized
  const isUserFullAccessAutorized = useCallback((): boolean => {
    if (
      memberProfileFromStore?.account?.subscription?.isFree?.value &&
      ["NEW", "REJECTED"].includes(memberProfileFromStore?.status?.value)
    ) {
      return false;
    } else if (
      memberProfileFromStore?.account?.subscription?.isFree?.value &&
      isUserAccountBlockedByAdmin()
    ) {
      return false;
    } else if (
      memberProfileFromStore?.status?.value === "VALIDATED" &&
      memberProfileFromStore?.account?.subscription?.lastPayment?.hasExpired !==
        false &&
      memberProfileFromStore?.approbation?.isApproved !== true
    ) {
      return false;
    } else {
      return (
        (!didUserHasToPay() &&
          memberProfileFromStore?.status?.value !== "NEW" &&
          memberProfileFromStore?.status?.value !== "NEW_EDITED" &&
          memberProfileFromStore?.status?.value !== "REJECTED" &&
          !isUserAccountBlockedByAdmin() &&
          isUserEmailVerified()) ||
        memberProfileFromStore?.account?.subscription?.isFree?.value
      );
    }
  }, [
    didUserHasToPay,
    isUserAccountBlockedByAdmin,
    isUserEmailVerified,
    memberProfileFromStore?.account?.subscription?.isFree?.value,
    memberProfileFromStore?.account?.subscription?.lastPayment?.hasExpired,
    memberProfileFromStore?.approbation?.isApproved,
    memberProfileFromStore?.status?.value,
  ]);

  // l'utilisateur vient de s'inscrire
  const isUserNew = useCallback(() => {
    return memberProfileFromStore?.status?.value === "NEW";
  }, [memberProfileFromStore?.status?.value]);

  const isUserUnableToHaveAccessToPayment = useCallback(() => {
    return (
      ["NEW", "NEW_EDITED"].includes(memberProfileFromStore?.status?.value) ||
      memberProfileFromStore?.approbation?.isApproved !== true
    );
  }, [
    memberProfileFromStore?.approbation?.isApproved,
    memberProfileFromStore?.status?.value,
  ]);

  const isUserInExchange = useCallback(() => {
    return memberCurrentExchangeFromStore !== null;
  }, [memberCurrentExchangeFromStore]);

  const isUserCloseExchangeInProgress = useCallback(() => {
    return !!memberProfileFromStore?.hasConversationClosedToConfirm;
  }, [memberProfileFromStore.hasConversationClosedToConfirm]);

  return {
    isUserNew: isUserNew(),
    isUserEmailVerified: isUserEmailVerified(),
    isUserEmailVerificationExpired: isUserEmailVerificationExpired(),
    isUserFullAccessAutorized:
      isUserFullAccessAutorized() && !isUserProfileModificationPending(),
    isUserProfileModificationApproved: isUserProfileModificationApproved(),
    isUserProfileModificationRejected: isUserProfileModificationRejected(),
    isUserProfileModificationPending: isUserProfileModificationPending(),
    didUserHasToPay: didUserHasToPay(),
    isUserAccountBlockedByAdmin: isUserAccountBlockedByAdmin(),
    isUserUnableToHaveAccessToPayment: isUserUnableToHaveAccessToPayment(),
    isUserInExchange: isUserInExchange(),
    isUserCloseExchangeInProgress: isUserCloseExchangeInProgress(),
  };
};

export default useUserRestrictions;
