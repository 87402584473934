import { TSignUp } from "@/features/auth/types";
import { TProfileCard } from "@/features/profile/Elements/ProfileCard/ProfileCard";
import { TempDataAction } from "./temp_data.actions";
import temp_dataTypes from "./temp_data.types";

interface ISidebarReducerState {
  sign_up: TSignUp | null;
  email: string;
  favorisList: TProfileCard[];
  fansList: TProfileCard[];
  blackList: TProfileCard[];
  demandLisk: TProfileCard[];
}

const INITIAL_STATE = {
  sign_up: null,
  email: '',
  favorisList: [],
  fansList: [],
  blackList: [],
  demandLisk: [],
};

const tempDataReducer = (
  state: ISidebarReducerState = INITIAL_STATE,
  action: TempDataAction
): ISidebarReducerState => {
  switch (action.type) {
    case temp_dataTypes.UPDATE_SIGNUP:
      return {
        ...state,
        sign_up: action.payload
      };

    case temp_dataTypes.UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload
      };

    case temp_dataTypes.UPDATE_LIST_FAVORIS:
      return {
        ...state,
        favorisList: action.payload
      };

    case temp_dataTypes.UPDATE_LIST_FANS:
      return {
        ...state,
        fansList: action.payload
      };

    case temp_dataTypes.UPDATE_BLACK_LIST:
      return {
        ...state,
        blackList: action.payload
      };

    case temp_dataTypes.UPDATE_DEMAND_LIST:
      return {
        ...state,
        demandLisk: action.payload
      };

    default:
      return state;
  }
};

export default tempDataReducer;
