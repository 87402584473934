import React from 'react'
import TermsAndConditionsForm  from '../forms/Terms&ConditionsForm/TermsAndConditionsForm'


const TermsAndConditions: React.FC = () => {
  return (
    <>
      <TermsAndConditionsForm />
    </>
  )
}

export default TermsAndConditions;
