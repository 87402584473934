export enum temp_dataTypes {
    UPDATE_SIGNUP = "UPDATE_SIGNUP",
    UPDATE_EMAIL = "UPDATE_EMAIL",
    UPDATE_LIST_FAVORIS = "UPDATE_LIST_FAVORIS",
    UPDATE_LIST_FANS = "UPDATE_LIST_FANS",
    UPDATE_BLACK_LIST = "UPDATE_BLACK_LIST",
    UPDATE_DEMAND_LIST = "UPDATE_DEMAND_LIST",
  }
  
export default temp_dataTypes;
  