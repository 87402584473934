import React from "react";
import classNames from "classnames";
import "./ChatMessage.style.scss";
import * as moment from "moment";
import "moment/locale/fr";
import { InlineIcon } from "@iconify/react";
import TextDisplayer from "../TextDisplayer/TextDisplayer";

export type TChatMessageProps = {
  _id?: string;
  type: "sent" | "received";
  gender: "MALE" | "FEMALE";
  fromStaff: boolean;
  time: string;
  pending: boolean;
  message: string;
  isValid: boolean;
  reason: string;
  note?: string;
  isRead?: boolean;
  issueDate?: string;
};

const ChatMessage: React.FC<TChatMessageProps> = ({
  _id,
  type,
  gender,
  fromStaff,
  pending,
  message,
  isValid,
  reason,
  time = moment.default(new Date()).format("LL") +
    " " +
    moment.default(new Date()).format("LT"),
  note = "",
  isRead = false,
  issueDate,
}) => {
  return (
    <div
      className={classNames("c-message-layout", {
        sent: type === "sent",
        received: type === "received",
        male: gender === "MALE",
        female: gender === "FEMALE",
        staff: fromStaff,
      })}
    >
      <div className="c-message-layout__container">
        {pending && !fromStaff && (
          <>
            <TextDisplayer
              forMessage={true}
              className={classNames("p", { pending: pending })}
              value={message}
            />
          </>
        )}

        {!pending && isValid && type === "sent" && !fromStaff && (
          <>
            <TextDisplayer
              className={classNames("p", { valid: isValid })}
              forMessage={true}
              value={message}
            />
            <p className={`checked  ${isRead ? "read" : ""}`}>
              {" "}
              <InlineIcon icon="akar-icons:double-check" />{" "}
            </p>
          </>
        )}

        {!pending && !isValid && type === "sent" && !fromStaff && (
          <>
            <TextDisplayer
              className={classNames("p pending")}
              style={{ marginBottom: "5px" }}
              forMessage={true}
              value={message}
            />
            <p className="rejected"> Ce message a été rejeté </p>
            <p className="reason">
              {" "}
              Raison: <b>{reason}</b>{" "}
            </p>
            <p className={`checked  ${isRead ? "read" : ""}`}>
              {" "}
              <InlineIcon icon="akar-icons:double-check" />{" "}
            </p>
          </>
        )}

        {!pending && !isValid && type === "received" && !fromStaff && (
          <>
            <TextDisplayer
              className={classNames("p pending")}
              style={{ marginBottom: "5px" }}
              forMessage={true}
              value={message}
            />
            <p className="rejected"> Ce message a été rejeté </p>
            <p className="reason">
              {" "}
              Raison: <b>{reason}</b>{" "}
            </p>
            {/* <p className={`checked`}>
              {" "}
              <InlineIcon icon="akar-icons:double-check" />{" "}
            </p> */}
          </>
        )}

        {!pending && type === "received" && !fromStaff && isValid && (
          <>
            <TextDisplayer
              className={classNames("p", { valid: isValid })}
              forMessage={true}
              value={message}
            />
            {/* <p className={`checked received`}>
              {" "}
              <InlineIcon icon="akar-icons:double-check" />{" "}
            </p> */}
          </>
        )}

        {pending && !fromStaff && (
          <p className="in-progress"> En cours de moderation ... </p>
        )}
        {!fromStaff && <small className="message-time"> {time} </small>}
        {fromStaff && (
          <>
            <p className="rejected"> Service de modération </p>
            <p className="p .">{message}</p>
            {/* <TextDisplayer className="p" forMessage={true} value={message} /> */}
            <small className="message-time-moderation"> {time} </small>
          </>
        )}
        {note && note.length > 0 && !fromStaff && (
          <div className="note">
            <small> Note du modérateur :</small>
            <span> {note} </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
