import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import texteArabe from "@/assets/images/png/texte-arabe.png";
import { Link, useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import "./TermsAndConditionsForm.styles.scss";
import { Toastify } from "@/utils/toast";
import TextCGU from "@/components/Text-CGU/TextCGU";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { selectSignUp } from "@/store/reducers/temp_data/temp_data.selector";
import { useSelector } from "react-redux";
import { TMemberInfos } from "@/features/profile/types";
import { useAppDispatch } from "@/store";
import {
  getToken,
  getMemberInfos,
  updateMemberProfile,
} from "@/store/reducers/member/member.actions";
import { apiSignupMember } from "../../api/auth.api";
import { TMemberProfile, TSignUp } from "../../types";
import { LoadingButton } from "@mui/lab";
import { jwtDecode } from "jwt-decode";

const TermsAndConditionsForm = () => {
  const sign_up = useSelector(selectSignUp);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [password, setPassword] = useState("");
  const handlePasswordChange = (e: any) => {
    setPassword(e?.target?.value);
  };

  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(() => {
    console.log(sign_up);

    if ((sign_up as TSignUp).password === password) {
      setLoading(true);
      apiSignupMember(sign_up as TSignUp)
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          const token = response.data.accessToken;
          const profile = response.data.profile as TMemberProfile;
          console.log({ profile });
          const decoded = jwtDecode(token);
          console.log(decoded);

          const infosUser: TMemberInfos = {
            _id: (decoded.sub as any)?._id || "",
            email: (decoded.sub as any)?.email,
            role: (decoded.sub as any)?.role,
            username: (decoded.sub as any)?.username,
            memberId: (decoded.sub as any)?.memberId,
            isVerified: (decoded.sub as any)?.isVerified,
            isInEmailVerificationProcess: (decoded.sub as any)
              ?.isInEmailVerificationProcess,
          };

          console.log({ infosUser });
          dispatch(getToken(token));
          dispatch(getMemberInfos({ ...infosUser }));
          dispatch(updateMemberProfile({ ...profile }));
          navigate(routePaths.profile, { replace: false });

          Toastify(`Votre compte a été créé avec succès`, "success", 5000);
        })
        .catch((error: any) => {
          console.log({ error });
          setLoading(false);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });
    } else {
      Toastify(
        `Le mot de passe ne correspond pas à celui que vous avez rentré lors de votre inscription`,
        "error",
        4000
      );
    }
  }, [dispatch, navigate, password, sign_up]);

  useEffect(() => {
    sign_up === null &&
      Toastify(`Veuillez procéder à votre inscription svp`, "warning", 4000);
    sign_up === null &&
      navigate(routePaths.authChildren.signUpAbsolute, { replace: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="terms-and-conditions-container">
      <div className="header-part">
        <h1 className="title">
          TU DOIS REGARDER LA VIDÉO <br />
          LIRE ET SIGNER TON CONTRAT D'ENGAGEMENT <br />
          AINSI QUE LES CONDITIONS D'UTILISATION.
        </h1>
      </div>
      <form
        className="form-part"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="media-container">
          <iframe
            src="https://player.vimeo.com/video/712936730?h=8e57687621"
            title="video-of-terms-and-condition"
            width="100%"
            height="400"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />

          <img src={texteArabe} alt="texte arabe" />
        </div>
        <TextCGU />
        <h1 className="title"> CONTRAT D'ENGAGEMENT </h1>
        <div className="checkbox-item">
          <Checkbox required />
          <p className="important">
            {" "}
            Je prends Allah 'Azawajel à témoin et...{" "}
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            J'atteste que je suis de la religion musulmane et ne suis{" "}
            <b> partisan(e) d’aucune secte. </b>{" "}
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            J'atteste que <b>je suis une personne majeure.</b>
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            Je m'engage à <b>ne pas mentir</b> concernant les informations
            relatives à mon profil.
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            Je m'engage à ne jamais rentrer en contact avec un membre de manière
            isolée en dehors du site.
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            Je m'engage à dénoncer tout frère ou sœur ayant un comportement
            suspect à Zawaj Sounnah.
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            {" "}
            Je m'engage à{" "}
            <b>
              ne pas demander où ne partager aucune photo en dehors du site
            </b>{" "}
            je ne ferais que des MOUQABALA.<b>(Physique ou VISIO)</b>.{" "}
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            <b>Je certifie avoir pris connaissance des C.G.U.</b>
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>
            Je m’engage à respecter les règles du site <b>ZAWAJ SOUNNAH</b>
          </p>
        </div>
        <div className="checkbox-item">
          <Checkbox required />
          <p>Je certifie avoir regardé la vidéo ci-dessus</p>
        </div>

        <Box sx={{ marginTop: "20px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={12}>
              <p>
                <b> Saisissez votre mot de passe en guise de signature</b>
              </p>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <TextField
                placeholder="Votre mot de passe"
                variant="outlined"
                label="Votre mot de passe"
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={handlePasswordChange}
                value={password}
                required
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: "15px" }}
              />
            </Grid>
          </Grid>
        </Box>

        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          color="tertiary"
          sx={{ marginTop: "30px" }}
          type="submit"
        >
          JE SIGNE MON ENGAGEMENT
        </LoadingButton>
        <div className="footer-part">
          <Link
            className="link-primary"
            to={routePaths.authChildren.signUpAbsolute}
          >
            Retourner sur le formulaire d'inscription
          </Link>
        </div>
      </form>
    </div>
  );
};

export default TermsAndConditionsForm;
