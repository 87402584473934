import React from 'react';
import Header from '../containers/Header/Header.container';
import CtNotifications from './Notification-content /Notification.content';

const Notification:React.FC = () => {
  return (
    <>
      <Header 
        title="NOTIFICATIONS"
        subtitle="ACTIVITÉS RÉCENTES" />
      <CtNotifications />
    </>
  )
}

export default Notification;