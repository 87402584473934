
export const checkTrueOrFalse = (val:any):boolean =>{
    let result = true;

    if(val === undefined){
        result = false;
    }

    if(val === ""){
        result = false;
    }

    if(val === null){
        result = false;
    }

    if(val === false) {
        result = false;
    }

    return result;
}