import React, { useState } from "react";
import "./UserRestrictionDisplayer.styles.scss";
import { routePaths } from "@/config";
import { InlineIcon } from "@iconify/react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useUserRestrictions from "@/hooks/useUserRestriction";
import { TMemberInfos } from "@/features/profile/types";
import {
  selectMemberInfos,
  selectMemberProfile,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import { apiConfirmEmail } from "@/features/auth/api/auth.api";
import { Toastify } from "@/utils/toast";
import { LoadingButton } from "@mui/lab";
import { TMemberProfile } from "@/features/auth/types";
import { selectIsPaymentPendingProcess } from "@/store/reducers/general_param/general_param.selector";

const UserRestrictionDisplayer = () => {
  const memberInfosFromStore = useSelector(selectMemberInfos) as TMemberInfos;
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const isPaymentProcesspending = useSelector(selectIsPaymentPendingProcess);

  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleConfirmEmail = () => {
    setLoading(true);
    apiConfirmEmail()
      .then((response: any) => {
        console.log(response);
        setLoading(false);
        setIsEmailSent(true);
        Toastify(`E-mail envoyé avec succès`, "success", 3000);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        setIsEmailSent(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const {
    isUserNew,
    isUserEmailVerified,
    isUserEmailVerificationExpired,
    didUserHasToPay,
    isUserProfileModificationRejected,
    isUserProfileModificationPending,
  } = useUserRestrictions();

  const navigate = useNavigate();

  const proceedPayment = () => {
    navigate(
      routePaths.profileChildren.subscriptionChildren
        .desactivateSubscriptionAbsolute,
      { replace: true }
    );
  };

  const goToProfile = () => {
    navigate(routePaths.profileChildren.myAccountAbsolute, {
      state: { from: "inside" },
    });
  };

  return (
    <div className="c-user-restriction-displayer-container">
      {isUserEmailVerified ? (
        <>
          {isUserNew && (
            <div className="box">
              <p className="header-text"> Bienvenue </p>
              <p className="body-text">
                As Salam Aleykoum et bienvenue à toi{" "}
                <b>{memberInfosFromStore?.username}</b>.
                <br /> Pour profiter pleinement de l'application veuillez
                procéder au
                <b className="primary" onClick={goToProfile}>
                  {" "}
                  remplissage des informations
                </b>{" "}
                concernant votre profil.{" "}
              </p>
              <Button
                className="btn"
                variant="contained"
                color="primary"
                onClick={goToProfile}
                startIcon={<InlineIcon icon="tabler:edit" />}
              >
                Remplir mon profil
              </Button>
            </div>
          )}

          {didUserHasToPay && (
            <div className="box">
              {memberProfileFromStore.account.subscription.lastPayment
                .purchaseId === null ? (
                <>
                  {isPaymentProcesspending ? (
                    <>
                      <p className="header-text">
                        {" "}
                        Paiement en cours de Traitement{" "}
                      </p>
                      <p className="body-text">
                        Merci d'avoir procédé au paiement de votre abonnement.
                        Veuillez patienter quelques instants
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="header-text"> Compte à activer </p>
                      <p className="body-text">
                        Félicitation! Votre compte a été <b>validé</b> par notre
                        équipe de modération.
                        <br /> Pour profiter pleinement de l'application
                        veuillez maintenant 
                        <b className="primary" onClick={proceedPayment}>
                          procéder au paiement
                        </b>
                          de votre abonnement selon la formule qui vous
                        convient.
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isPaymentProcesspending ? (
                    <>
                      <p className="header-text">
                        {" "}
                        Paiement en cours de Traitement{" "}
                      </p>
                      <p className="body-text">
                        Merci d'avoir procédé au paiement de votre abonnement.
                        Veuillez patienter quelques instants
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="header-text"> Compte inactif </p>
                      <p className="body-text">
                        {" "}
                        Votre compte <b>n'est pas actif</b> pour défaut de
                        paiement.
                        <br /> Pour profiter pleinement de l'application
                        veuillez{" "}
                        <b className="primary" onClick={proceedPayment}>
                          procéder au paiement
                        </b>{" "}
                        de votre abonnement{" "}
                      </p>
                    </>
                  )}
                </>
              )}
              {!isPaymentProcesspending && (
                <Button
                  className="btn"
                  variant="contained"
                  color="primary"
                  onClick={proceedPayment}
                  startIcon={<InlineIcon icon="fluent:payment-32-regular" />}
                >
                  Procéder au paiement
                </Button>
              )}
            </div>
          )}

          {isUserProfileModificationRejected && (
            <div className="box">
              {memberProfileFromStore?.status?.reason?.length > 0 ? (
                <>
                  <p className="header-text"> Modifications rejetées </p>
                  <p className="body-text">
                    {" "}
                    Les modifications apportées à votre profil ont été{" "}
                    <b> rejetées</b> par notre équipe de modération pour la(les)
                    raison(s) suivante(s):
                    <ul
                      style={{
                        textAlign: "left",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {memberProfileFromStore?.status?.reason?.map(
                        (x, index) => (
                          <li key={index}>
                            {x.category === "identity" && (
                              <>
                                Dans la{" "}
                                <b
                                  onClick={() => {
                                    navigate(
                                      routePaths.profileChildren
                                        .myAccountChildren.ficheAbsolute,
                                      { replace: true }
                                    );
                                  }}
                                >
                                  <InlineIcon
                                    icon="akar-icons:link-out"
                                    fontSize={12}
                                  />
                                  Fiche d'information
                                </b>
                                :
                              </>
                            )}
                            {x.category === "profile" && (
                              <>
                                Dans la rubrique{" "}
                                <b
                                  onClick={() => {
                                    navigate(
                                      routePaths.profileChildren
                                        .myAccountChildren.formAbsolute,
                                      {
                                        replace: true,
                                        state: { formStep: "1" },
                                      }
                                    );
                                  }}
                                >
                                  <InlineIcon
                                    icon="akar-icons:link-out"
                                    fontSize={12}
                                  />
                                  Profil
                                </b>
                                :
                              </>
                            )}
                            {x.category === "religiousAspects" && (
                              <>
                                Dans la rubrique{" "}
                                <b
                                  onClick={() => {
                                    navigate(
                                      routePaths.profileChildren
                                        .myAccountChildren.formAbsolute,
                                      {
                                        replace: true,
                                        state: { formStep: "2" },
                                      }
                                    );
                                  }}
                                >
                                  <InlineIcon
                                    icon="akar-icons:link-out"
                                    fontSize={12}
                                  />
                                  Aspect religieux
                                </b>
                                :
                              </>
                            )}
                            {x.category === "religiousPractices" && (
                              <>
                                Dans la rubrique{" "}
                                <b
                                  onClick={() => {
                                    navigate(
                                      routePaths.profileChildren
                                        .myAccountChildren.formAbsolute,
                                      {
                                        replace: true,
                                        state: { formStep: "3" },
                                      }
                                    );
                                  }}
                                >
                                  <InlineIcon
                                    icon="akar-icons:link-out"
                                    fontSize={12}
                                  />
                                  Pratiques religieuses
                                </b>
                                :
                              </>
                            )}
                            {x.category === "healthAndDisability" && (
                              <>
                                Dans la rubrique{" "}
                                <b
                                  onClick={() => {
                                    navigate(
                                      routePaths.profileChildren
                                        .myAccountChildren.formAbsolute,
                                      {
                                        replace: true,
                                        state: { formStep: "4" },
                                      }
                                    );
                                  }}
                                >
                                  <InlineIcon
                                    icon="akar-icons:link-out"
                                    fontSize={12}
                                  />
                                  Santé et handicap
                                </b>
                                :
                              </>
                            )}
                            <b className="value">{x.value}</b>
                          </li>
                        )
                      )}
                    </ul>
                    <br /> Veuillez apporter les corrections adéquates à vos
                    modifications pour qu'elles puissent être prises en compte.
                  </p>
                </>
              ) : (
                <>
                  <p className="header-text"> Profil en cours de modération </p>
                  <p className="body-text">
                    Les modifications apportées à votre profil sont toujours{" "}
                    <b> en cours de vérification</b> par notre équipe de
                    modération.
                    <br />
                    <br />
                    Avant l'approbation de vos modifications , votre profil sera
                    inaccessible aux autres membres.
                  </p>
                </>
              )}
            </div>
          )}

          {isUserProfileModificationPending && (
            <div className="box">
              {memberProfileFromStore?.status?.value === "VALIDATED" ? (
                <p className="header-text"> Profil incomplet </p>
              ) : (
                <p className="header-text"> Profil en attente de validation </p>
              )}

              <div className="body-text">
                {memberProfileFromStore?.status?.value === "VALIDATED" ? (
                  <></>
                ) : (
                  <>
                    Les modifications que vous avez apportées à votre profil
                    sont 
                    <b>en cours de vérification</b> par notre équipe de
                    modération.
                    <br />
                    <br />
                    Vos modifications peuvent être:
                    <br />
                    <br />
                  </>
                )}

                <div style={{ textAlign: "left", paddingLeft: "0px" }}>
                  {memberProfileFromStore?.status?.value === "VALIDATED" ? (
                    <></>
                  ) : (
                    <>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "10px",
                        }}
                      >
                        <b>• Acceptées</b>
                        <span>
                          Votre profil devient visible par les autres membres
                          qui ne sont pas en échange.
                        </span>
                      </p>
                      <br />
                      <p
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "10px",
                        }}
                      >
                        <b>• Refusées</b>
                        <span>
                          Vous devrez donc
                          <b className="primary" onClick={goToProfile}>
                            {" "}
                            modifier votre profil{" "}
                          </b>{" "}
                          conformément aux recommendations de notre équipe de
                          modération.
                        </span>
                      </p>
                    </>
                  )}

                  {["NEW_EDITED", "VALIDATED"].includes(
                    memberProfileFromStore?.status?.value
                  ) && (
                    <>
                      <br />
                      <p
                        style={{
                          color: "var(--ui-medium)",
                          background: "var(--ui-light)",
                          padding: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        <b
                          style={{
                            color: "var(--ui-dark)",
                            fontSize: "1.1rem",
                          }}
                        >
                          Vous êtes nouveau ?
                        </b>{" "}
                        <br />
                        <br />
                        Rassurez-vous d'avoir entièrement rempli toutes les
                        rubriques du profil à savoir :<br />
                        <ul style={{ fontWeight: "600", marginLeft: "20px" }}>
                          <li
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "1" },
                                }
                              );
                            }}
                          >
                            <b>Profil</b>
                          </li>
                          <li
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "2" },
                                }
                              );
                            }}
                          >
                            <b>Aspect religieux</b>
                          </li>
                          <li
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "3" },
                                }
                              );
                            }}
                          >
                            <b>Pratiques religieuses</b>
                          </li>
                          <li
                            onClick={() => {
                              navigate(
                                routePaths.profileChildren.myAccountChildren
                                  .formAbsolute,
                                {
                                  replace: true,
                                  state: { formStep: "4" },
                                }
                              );
                            }}
                          >
                            <b>Santé & handicap</b>
                          </li>
                        </ul>
                        Faute de quoi vous serez toujours en{" "}
                        <b>attente de validation</b>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {!memberProfileFromStore.activation.isActivate && (
            <>
              <div className="box">
                <p className="header-text"> Profil désactivé </p>
                <p className="body-text">
                  {" "}
                  Votre profil a été désactivé pour la raison suivante : <br />{" "}
                  <b>{memberProfileFromStore.activation.reason}</b>
                </p>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="box">
          <p className="header-text"> E-mail non vérifié </p>
          {isUserEmailVerificationExpired ? (
            <>
              <p className="body-text">
                {isEmailSent ? (
                  <>
                    {" "}
                    Un mail vous a été envoyé à l'adresse suivante:{" "}
                    <b>{memberInfosFromStore.email}</b> <br />
                    <br />
                    <b>
                      Veuillez consulter votre boîte e-mail. Si vous ne trouvez
                      pas notre message, pensez à vérifier également dans votre
                      dossier de courriers indésirables (spams).
                    </b>
                    <br />
                    <br />
                    <LoadingButton
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      className="btn"
                      color="primary"
                      onClick={handleConfirmEmail}
                      startIcon={<InlineIcon icon="mingcute:send-fill" />}
                    >
                      Renvoyer le lien
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    Votre lien de vérification d'e-mail a expiré.
                    <br />
                    <br />
                    <LoadingButton
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      className="btn"
                      color="primary"
                      onClick={handleConfirmEmail}
                      startIcon={<InlineIcon icon="mingcute:send-fill" />}
                    >
                      Renvoyer le lien
                    </LoadingButton>
                  </>
                )}
              </p>
            </>
          ) : (
            <>
              <p className="body-text">
                Nous remarquons que
                <b> vous n'avez pas encore vérifié votre adresse e-mail</b> pour
                accéder à notre plateforme.
                <br />
                <br />
                La vérification de votre adresse e-mail est une étape importante
                pour assurer{" "}
                <b>
                  la sécurité de votre compte et garantir que vous recevrez des
                  notifications importantes
                </b>{" "}
                de notre part.
                <br />
                <br />
                <b>
                  Veuillez consulter votre boîte e-mail à l'adresse suivante:{" "}
                  <b style={{ color: "var(--ui-primary)" }}>
                    {memberInfosFromStore.email}{" "}
                  </b>
                </b>
                <br />
                Si vous ne trouvez pas notre message, pensez à vérifier
                également dans votre dossier de courriers indésirables (spams).
              </p>
              <br />
              <>
                Vous n'avez pas reçu d'e-mail ?
                <br />
                <br />
                <LoadingButton
                  loading={loading}
                  loadingPosition="center"
                  variant="contained"
                  className="btn"
                  color="primary"
                  onClick={handleConfirmEmail}
                  startIcon={<InlineIcon icon="mingcute:send-fill" />}
                >
                  Renvoyer l'e-mail
                </LoadingButton>
              </>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserRestrictionDisplayer;
