import React, { useContext, useEffect, useState } from "react";
import { selectMessageNotif } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import "./EchangeInfos.styles.scss";
import { InlineIcon } from "@iconify/react";
import * as moment from "moment";
import "moment/locale/fr";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Slide,
  TextField,
} from "@mui/material";
import ModalFullProfil from "@/components/Modal-full-profile/ModalFullProfil";
import ReglementDuChat from "./Modals-components/Reglement-du-chat/ReglementDuChat";
import QuestionAPoser from "./Modals-components/Questions-a-poser/QuestionAPoser";
import CommentSeVoir from "./Modals-components/Comment-se-voir/CommentSeVoir";
import { TMemberCurrentExchange } from "@/features/profile/types";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useFormik } from "formik";
import { HighlightOffRounded } from "@mui/icons-material";
import { apiSignalMember } from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import { store } from "@/store";
import {
  updateNbreMessageReceive,
  updateNbreMessageSent,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import { ExchangeContext } from "../../Exchange.content";
import { styled } from "@mui/material/styles";
import { relaunch } from "@/features/profile/api/exchange.api";
import useExchangeRole from "@/hooks/useExchangeRole";
import { calculerAge } from "@/utils/calculerAge";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

interface EchangeInfosProps {
  isDisplayedInModal?: boolean;
  isDiscussing?: boolean;
  memberCurrentExchange: TMemberCurrentExchange | null;
}
const EchangeInfos = ({
  isDisplayedInModal = false,
  isDiscussing = false,
  memberCurrentExchange,
}: EchangeInfosProps) => {
  const { receiver } = useExchangeRole();

  const nbre_message_notif = useSelector(selectMessageNotif);

  const [openActionType, setOpenActionType] = React.useState<
    "profil" | "règlement" | "question" | "voir" | ""
  >("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [openSignal, setOpenSignal] = React.useState(false);
  const handleOpenSignal = () => setOpenSignal(true);
  const handleCloseSignal = () => {
    formik.resetForm();
    setOpenSignal(false);
  };

  const [openConfirmedSignal, setOpenConfirmedSignal] = React.useState(false);
  const handleOpenConfirmedSignal = () => setOpenConfirmedSignal(true);
  const handleCloseConfirmedSignal = () => setOpenConfirmedSignal(false);

  const [openEndUp, setOpenEndUp] = React.useState(false);
  const handleOpenEndUp = () => setOpenEndUp(true);
  const handleCloseEndUp = () => {
    formikEndUp.resetForm();
    setOpenEndUp(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [openModalProfil, setOpenModalProfil] = React.useState(false);
  const handleOpenModalProfil = () => setOpenModalProfil(true);
  const handleCloseModalProfil = () => setOpenModalProfil(false);

  const [openEndUpExchange, setOpenEndUpExchange] = React.useState(false);
  const handleOpenEndUpExchange = () => setOpenEndUpExchange(true);
  const handleCloseEndUpExchange = () => setOpenEndUpExchange(false);

  const handleOpenAction = (
    actionType: "profil" | "règlement" | "question" | "voir"
  ) => {
    handleOpenModal();
    setOpenActionType(actionType);
  };

  const validationSchema = yup.object({
    motif: yup.string().required(" la raison est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedSignal();
      }, 100);
    },
  });

  const validationSchemaEndUp = yup.object({
    motif: yup
      .string()
      .min(
        10,
        "Votre message est trop court, par respect pour l'utilisateur veuillez écrire un réel motif de clôture."
      )
      .required(" la raison est obligatoire  "),
  });

  const formikEndUp = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchemaEndUp,
    onSubmit: (values: any) => {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
        handleOpenEndUpExchange();
      }, 100);
    },
  });

  const signalMember = () => {
    setLoading3(true);

    apiSignalMember({
      member_id: receiver?._id || "",
      note: formik.values.motif,
    })
      .then((response: any) => {
        setLoading3(false);
        console.log(response);
        Toastify(
          ` Le signalement de ${receiver?.username || ""} a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      });
  };

  const { endUpExchangeFct, loadingEndup } = useContext(ExchangeContext);

  const endUpExchange = () => {
    endUpExchangeFct(
      memberCurrentExchange?.conversationId || memberCurrentExchange?._id || "",
      formikEndUp.values.motif,
      receiver?.username || ""
    );
    formikEndUp.resetForm();
    handleCloseEndUp();
    handleCloseEndUpExchange();

    store.dispatch(updateNbreMessageSent(0));
    store.dispatch(updateNbreMessageReceive(0));
  };

  const relancer = () => {
    relaunch(receiver?._id || "")
      .then(() => {
        Toastify(`Relance effectuée avec succès`, "success", 5000);
      })
      .catch((error) => {
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <>
      <div className="c-echange-infos">
        <div className="icon-circle">
          <InlineIcon icon="fluent-emoji-high-contrast:handshake" />
          {/* <InlineIcon icon="la:handshake-solid" /> */}
        </div>
        {memberCurrentExchange === null && <p> Vous n'êtes pas en échange </p>}

        {memberCurrentExchange !== null && !isDiscussing && (
          <>
            {!receiver?.hasSubscription && (
              <small
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "500",
                  margin: "10px auto",
                  color: "var(--ui-primary)",
                }}
              >
                <b>
                  ⚠️&nbsp;
                  {receiver?.username}
                </b>
                &nbsp; avec qui vous êtes en échange ne dispose plus
                d'abonnement
              </small>
            )}

            <p> Je suis actuellement en échange avec </p>
            <span className="name">{receiver?.username.toUpperCase()}</span>
            {memberCurrentExchange?.responseDate !== null && (
              <small>
                {" "}
                Depuis le{" "}
                <b>
                  {moment
                    .default(memberCurrentExchange?.responseDate || "")
                    .format("LLL")}
                </b>{" "}
              </small>
            )}

            <div className="btn-container">
              <Button
                variant="contained"
                onClick={() => {
                  store.dispatch(updateOpenDiscussion(true));
                }}
                color="primary"
              >
                <StyledBadge
                  overlap="circular"
                  variant="dot"
                  color="secondary"
                  badgeContent={nbre_message_notif}
                >
                  <>Accéder à l'échange</>
                </StyledBadge>
              </Button>
              <Button
                variant="outlined"
                style={{ color: "var(--ui-medium)" }}
                color="tertiary"
                onClick={handleOpenEndUp}
              >
                Clôturer l'échange
              </Button>
            </div>
          </>
        )}

        {isDiscussing && (
          <>
            {!isDisplayedInModal && (
              <Button
                color="primary"
                className="return"
                startIcon={<InlineIcon icon="akar-icons:arrow-back" />}
                onClick={() => {
                  store.dispatch(updateOpenDiscussion(false));
                }}
              >
                Retour
              </Button>
            )}

            {!receiver?.hasSubscription && (
              <small
                style={{
                  width: "80%",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "500",
                  margin: "10px auto",
                  color: "var(--ui-primary)",
                }}
              >
                <b>
                  ⚠️&nbsp;
                  {receiver?.username.toUpperCase()}
                </b>
                &nbsp; avec qui vous êtes en échange ne dispose plus
                d'abonnement
              </small>
            )}

            <p> Je suis actuellement en échange avec </p>
            <span className="name">{receiver?.username.toUpperCase()}</span>
            {memberCurrentExchange?.responseDate !== null && (
              <small className="inside">
                {" "}
                Depuis le{" "}
                <b>
                  {moment
                    .default(memberCurrentExchange?.responseDate)
                    .format("LLL")}
                </b>{" "}
              </small>
            )}

            <Button
              variant="contained"
              className="btn-inside"
              onClick={() => {
                handleOpenModalProfil();
              }}
              color="primary"
            >
              Voir le profil complet
            </Button>
            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("règlement");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Règlement du chat
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("question");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Questions à lui poser
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={() => {
                handleOpenAction("voir");
              }}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Comment se voir
            </Button>

            {false && (
              <Button
                variant="outlined"
                className="btn-inside"
                onClick={() => {}}
                style={{ color: "var(--ui-medium)" }}
                color="tertiary"
              >
                Actualiser la page
              </Button>
            )}

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={handleOpenEndUp}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Clôturer l'échange
            </Button>

            <Button
              variant="outlined"
              className="btn-inside"
              onClick={handleOpenSignal}
              style={{ color: "var(--ui-medium)" }}
              color="tertiary"
            >
              Faire un signalement
            </Button>

            <Button
              variant="contained"
              className="btn-inside"
              onClick={relancer}
              color="primary"
            >
              Envoyer une notification
            </Button>
          </>
        )}
      </div>

      {/* MODALS PART */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openModal} mountOnEnter unmountOnExit>
          <div className="modal-exchange-container">
            <div className="modal-exchange-container__title-part">
              <IconButton
                className="back-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="tabler:chevron-left" />
              </IconButton>
              <div className="title-infos-username-age">
                <div className="username-age">
                  <p className="username">
                    <>
                      {openActionType === "règlement" && <>Règlement du chat</>}
                      {openActionType === "question" && <>Questions à poser</>}
                      {openActionType === "voir" && <>Comment se voir</>}
                    </>
                  </p>
                </div>
              </div>
              <IconButton
                className="close-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="iconamoon:close-bold" />
              </IconButton>
            </div>
            <div className="modal-exchange-container__body-part">
              <>
                {openActionType === "règlement" && (
                  <>
                    <ReglementDuChat closeModal={handleCloseModal} />
                  </>
                )}
                {openActionType === "question" && (
                  <>
                    <QuestionAPoser closeModal={handleCloseModal} />
                  </>
                )}
                {openActionType === "voir" && (
                  <>
                    <CommentSeVoir closeModal={handleCloseModal} />
                  </>
                )}
              </>
            </div>
          </div>
        </Slide>
      </Modal>
      {openModalProfil && (
        <ModalFullProfil
          _id={receiver?._id}
          openModal={openModalProfil}
          closeModal={handleCloseModalProfil}
          isOnline={true}
          isVerified={true}
          hasMore={true}
          date_of_creation={"12/12/1892"}
          username={receiver?.username}
          age={calculerAge(new Date(receiver?.birthDate || ""))}
          town={receiver?.city}
          country={receiver?.country}
          origine={receiver?.homeCountry}
          profil_id={(receiver?.memberId || 0).toString()}
          nationalite={receiver?.citizenship}
          progressValue={receiver?.rateOfCompletion}
          sendStatut={""}
          fromExchange={true}
        />
      )}
      {/* End of MODALS PARTS */}

      {/* SIGNAL */}
      <Modal
        open={openSignal}
        onClose={handleCloseSignal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form className="form-raison-part" onSubmit={formik.handleSubmit}>
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseSignal}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du signalement"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formik.handleChange}
              value={formik.values.motif}
              error={formik.touched.motif && Boolean(formik.errors.motif)}
              helperText={
                formik.touched.motif && (formik.errors.motif as string)
              }
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseSignal}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    SIGNALER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of SIGNAL */}

      {/* SIGNALER  DIALOG PART */}
      <Dialog
        open={openConfirmedSignal}
        onClose={handleCloseConfirmedSignal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Signalement"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Attention!!!</b> cette action est irréversible.
            <br />
            Vous êtes sur le point de <b>signaler</b> ce profil. Êtes-vous
            sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmedSignal}>Annuler</Button>
          <LoadingButton
            id="id-signal-btn"
            loading={loading3}
            loadingPosition="center"
            onClick={signalMember}
            autoFocus
          >
            Signaler
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of SIGNALER  DIALOG PART */}

      {/* ENDUP */}
      <Modal
        open={openEndUp}
        onClose={handleCloseEndUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form
            className="form-raison-part"
            onSubmit={formikEndUp.handleSubmit}
          >
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseEndUp}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison de la clôture"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formikEndUp.handleChange}
              value={formikEndUp.values.motif}
              error={
                formikEndUp.touched.motif && Boolean(formikEndUp.errors.motif)
              }
              helperText={
                formikEndUp.touched.motif &&
                (formikEndUp.errors.motif as string)
              }
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseEndUp}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading2}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    CLÔTURER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of ENDUP */}

      {/* CLOTURER  DIALOG PART */}
      <Dialog
        open={openEndUpExchange}
        onClose={handleCloseEndUpExchange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Clôturer les échanges"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
            Vous êtes sur le point de <b> clôturer </b> vos échanges avec{" "}
            <b>{receiver?.username}</b> . Êtes-vous sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEndUpExchange}>Annuler</Button>
          <LoadingButton
            loading={loadingEndup}
            loadingPosition="center"
            variant="contained"
            color="primary"
            id="id-remove-blacklist-btn"
            onClick={endUpExchange}
            type="submit"
          >
            {" "}
            Clôturer{" "}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of CLOTURER DIALOG PART */}
    </>
  );
};

export default EchangeInfos;
