import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NotificationCard from "../../Elements/NotificationCard/NotificationCard";
import "./Notification.styles.scss";
import NoData from "@/components/No-data/NoData";
import { TNotification, TPaginationResult } from "../../types";
import { store } from "@/store";
import { updateNbreNotificationSocket } from "@/store/reducers/general_param/general_param.actions";
import { socketChannels } from "@/config/socketChannels";
import { AppSocketContext } from "@/routes";
import {
  apiGetNotification,
  apiRemoveNotification,
} from "../../api/notification.api";
import { Toastify } from "@/utils/toast";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";

const CtNotifications = () => {
  const { socketIO } = useContext(AppSocketContext);

  const [notifArr, setNotifArr] = useState<TNotification[]>([]);
  const [loading, setLoading] = useState(false);

  const LIMIT_MEMBER = 100;
  const [page, setPage] = useState(1);
  const scrollRef = useRef<any>();
  const [paginationResult, setPaginationResult] =
    useState<TPaginationResult | null>(null);

  const getPaginatedNotification = useCallback((page: number) => {
    setLoading(true);
    apiGetNotification(page, LIMIT_MEMBER)
      .then((response: any) => {
        setLoading(false);
        console.log(response.data.result);

        let tempList: TNotification[] = response.data.result.map(
          (data: any) => {
            return {
              id: data._id,
              body: data.message,
              date: data.date,
              sender: "SENDER", //data.sender,
              subject: data.subject,
            };
          }
        );

        setPaginationResult(response.data.paginationResult);
        setNotifArr((prevData) => [...prevData, ...tempList]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  }, []);

  const removeItem = useCallback(
    (notification_id: string) => {
      console.log(notification_id);
      setLoading(true);
      apiRemoveNotification(notification_id)
        .then((response: any) => {
          console.log(response);

          setNotifArr(notifArr.filter((x) => x.id !== notification_id));

          Toastify(`Notification supprimée avec succès`, "success", 5000);
        })
        .catch((error) => {
          console.log("💌", notification_id);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [notifArr]
  );

  useEffect(() => {
    store.dispatch(updateNbreNotificationSocket(0));
    getPaginatedNotification(1);
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = 0;
    }

    return () => {
      store.dispatch(updateNbreNotificationSocket(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socketIO) {
      socketIO.on(socketChannels.notification_channel, (data) => {
        console.log("Socket Notifications ✌️", data);

        const newNotif: any = {
          id: data.id,
          body: data.message,
          date: data.date,
          sender: "SENDER", //data.sender,
          subject: data.subject,
        };

        setNotifArr([
          {
            ...newNotif,
          },
          ...notifArr,
        ]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, notifArr]);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
        if (paginationResult?.nextPage === null) {
        } else {
          const nextPage = page + 1;
          setPage(nextPage);
          getPaginatedNotification(nextPage);
        }
      }
    }
  }, [getPaginatedNotification, loading, page, paginationResult?.nextPage]);

  useEffect(() => {
    const container = scrollRef.current;

    container && container.addEventListener("scroll", handleScroll);

    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading, page, scrollRef]);

  return (
    <>
      <div className="ct-notification">
        <div className="ct-notification__body-part" ref={scrollRef}>
          <>
            {!loading && notifArr.length === 0 ? (
              <NoData />
            ) : (
              notifArr.map((value, index) => {
                return (
                  <NotificationCard
                    key={value.id + index}
                    onClose={() => {
                      removeItem(value.id || "");
                    }}
                    title={value.subject}
                    message={value.body}
                    date={value.date}
                  />
                );
              })
            )}
            {loading && (
              <img
                src={require("@/assets/images/gif/loadmore.gif")}
                height="70"
                width="70"
                className="load-more"
                alt="load-more"
              />
            )}
          </>
        </div>
      </div>
      {false && <ModalUserRestrictionWhenPaid />}
    </>
  );
};

export default CtNotifications;
