import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import general_paramReducer from "./general_param/general_param.reducer";
import memberReducer from "./member/member.reducer";
import sidebarReducer from "./sidebar/sidebar.reducer";
import tempDataReducer from "./temp_data/temp_data.reducer";

export const persistConfig = {
  key: "zs_myspace_staging",
  storage,
  whitelist: ["sidebar", "member", "general_param", "temp_data"],
  blacklist: [],
};

const topReducer = combineReducers({
  sidebar: sidebarReducer,
  member: memberReducer,
  general_param: general_paramReducer,
  temp_data: tempDataReducer,
});

const rootReducer = (state: any, action: any) => {
  // when RESET action is dispatched it will reset redux state
  if (action.type === "CLEAR_STORE") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem("persist:zs_myspace_staging");

    state = {};
  }
  // if (action.type === authTypes.LOGOUT) {
  //   state = undefined;
  // }

  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
