import React from 'react';
import Header from '../containers/Header/Header.container';
import CtFonctionnement from './Fonctionnement-content/Fonctionnement.content';

const Fonctionnement:React.FC = () => {
  return (
    <>
      <Header 
        title=" FONCTIONNEMENT "
        subtitle="DU SITE  " />
      <CtFonctionnement />
    </>
  )
}

export default Fonctionnement;