import React from 'react';
import Header from '../containers/Header/Header.container';
import CtMyAccount from './MyAccount-content/MyAccount.content';

const MyAccount:React.FC = () => {
  return (
    <>
      <Header 
        title="À PROPOS DE MOI"
        subtitle="CE QU'IL FAUT SAVOIR À MON SUJET" />
      <CtMyAccount />
    </>
  )
}

export default MyAccount;