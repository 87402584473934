import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { TThemeColors } from "@/utils/convertThemeColorsToRootColors";

export const SideMenuHeader = styled.button`
  height: 49px;
  background: none;
  border: none;
  color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
  font-weight: 900;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  width: 100%;
  text-align: left;
  cursor: pointer;

  svg {
    margin-left: 15px;
  }
`;

const sideMenuLink = css`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 1rem;
  line-height: 24px;
  color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
  height: 49px;
  padding-left: 18px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`;

export const SideMenuContainer = styled.div<{
  duration: number;
  open: boolean;
}>`
  position: relative;
  color: #fff;
  padding-left: 18px;
  border-radius: 15px;
  height: 49px;
  overflow: hidden;

  .not_display {
    display: none;
  }

  ${({ duration }) =>
    `transition: height ${duration / 1000}s ease, margin-bottom ${
      duration / 1000
    }s ease, margin-top 0.05s ease`};

  ${({ open }) => {
    if (open) {
      return css`
        margin-top: 1rem;
        margin-right: 1rem;
        background-color: ${(props) =>
          (props.theme.colors.ui as TThemeColors).secondary};

        ${SideMenuHeader.toString()} {
          color: ${(props) =>
            (props.theme.colors.ui as TThemeColors).primary} !important;
        }
      `;
    }
  }}

  ${SideMenuHeader.toString()} {
    height: 49px;
    background: none;
    border: none;
    color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
    font-weight: 900;
    font-size: 1rem;
    width: 100%;
    text-align: left;
    cursor: pointer;

    svg {
      margin-left: 15px;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 1rem;

    & > li a {
      display: block;
      color: ${(props) => (props.theme.colors.ui as TThemeColors).primary};
      padding: 0.75rem 0;
      padding-left: 15px;
    }

    & > li a.active {
      font-weight: bold;
    }

    & > li:not(:last-child) {
      border-bottom: 1px solid rgba(196, 196, 196, 0.53);
    }
  }
`;

export const SideMenuLinkContainer = styled(Link)<{
  active: string;
  down_lg: string;
}>`
  ${sideMenuLink};

  &.not_available {
    color: var(--ui-gray);
  }

  ${({ active, down_lg }) =>
    down_lg === "false" &&
    active === "true" &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: -20px;
        right: 0;
        width: 20px;
        height: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: 7.5px 7.5px 0 7.5px
          ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -20px;
        right: 0;
        width: 20px;
        height: 20px;
        border-top-right-radius: 20px;
        box-shadow: 7.5px -7.5px 0 7.5px ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
      }

      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).secondary};
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).primary} !important;

      &.not_available {
        color: var(--ui-dark) !important;
      }
    `};

  ${({ active, down_lg }) =>
    down_lg === "true" &&
    active === "true" &&
    css`
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      border-radius: 30px;
      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).secondary};
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).primary} !important;

      &.not_available {
        color: var(--ui-dark) !important;
      }
    `};

  svg {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }
`;
