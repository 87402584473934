import React from 'react';
import Header from '../containers/Header/Header.container';
import CtSearch from './Search-content/Search.content';

const Search:React.FC = () => {
  return (
    <>
      <Header 
        title="MES CRITÈRES DE RECHERCHE"
        subtitle="FAIS TA SÉLECTION" />
      <CtSearch />
    </>
  )
}

export default Search;