import {
  TextField,
  Box,
  Grid,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  Radio,
  RadioGroup,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { Toastify } from "@/utils/toast";
import * as yup from "yup";
import "./Profil.step.styles.scss";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import { TCountry } from "@/features/profile/types";
import { InlineIcon } from "@iconify/react";
import { MultipleSelectChip } from "@/components/MultipleSelectChip/MultipleSelectChip";
import { apiSaveProfile } from "@/features/profile/api/profile.api";
import { store } from "@/store";
import { updateMemberProfile } from "@/store/reducers/member/member.actions";
import {
  selectBodyshape,
  selectEthnies,
} from "@/store/reducers/general_param/general_param.selector";
import classNames from "classnames";
import { checkTrueOrFalse } from "../../../utils/account.utils";
import { TMemberProfile } from "@/features/auth/types";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";

interface ProfilStepProps {
  chooseStep: React.Dispatch<React.SetStateAction<number>>;
  scrollTop: () => void;
}

const ProfilStep = ({ chooseStep, scrollTop }: ProfilStepProps) => {
  const ethnies = useSelector(selectEthnies);
  const bodyshapeFromStore = useSelector(selectBodyshape);

  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const [origine, setOrigine] = useState<TCountry>({
    country: memberProfileFromStore?.infos?.profile?.homeCountry || "",
    abbreviation: memberProfileFromStore?.infos?.profile?.homeCountryCode || "",
  });

  const handleOrigineChange = (event: any, value: TCountry | null) => {
    if (value === null) {
      setOrigine({ country: "", abbreviation: "" });
      formik.setFieldValue("origine", "");
    } else {
      formik.setFieldValue("origine", value.country.trim());
      setOrigine(value as TCountry);
    }
  };

  const [origineAutre, setOrigineAutre] = useState<TCountry>({
    country: memberProfileFromStore?.infos?.profile?.otherHomeCountry || "",
    abbreviation:
      memberProfileFromStore?.infos?.profile?.otherHomeCountryCode || "",
  });

  const handleOrigineAutreChange = (event: any, value: TCountry | null) => {
    console.log("🐤", value);
    if (value === null) {
      setOrigineAutre({ country: "", abbreviation: "" });
      formik.setFieldValue("origine_autre", "");
    } else {
      formik.setFieldValue("origine_autre", value.country.trim());
      setOrigineAutre(value as TCountry);
    }
  };

  const [nationalite, setNationalite] = useState<TCountry>({
    country: memberProfileFromStore?.infos?.profile?.citizenship || "",
    abbreviation: memberProfileFromStore?.infos?.profile?.citizenshipCode || "",
  });

  const handleNationaliteChange = (event: any, value: TCountry | null) => {
    if (value === null) {
      setNationalite({ country: "", abbreviation: "" });
      formik.setFieldValue("nationalite", "");
    } else {
      formik.setFieldValue("nationalite", value.country.trim());
      setNationalite(value as TCountry);
    }
  };

  const [nationaliteAutre, setNationaliteAutre] = useState<TCountry>({
    country: memberProfileFromStore?.infos?.profile?.otherCitizenship || "",
    abbreviation:
      memberProfileFromStore?.infos?.profile?.otherCitizenshipCode || "",
  });

  const handleNationaliteAutreChange = (event: any, value: TCountry | null) => {
    if (value === null) {
      setNationaliteAutre({ country: "", abbreviation: "" });
      formik.setFieldValue("nationalite_autre", "");
    } else {
      formik.setFieldValue("nationalite_autre", value.country.trim());
      setNationaliteAutre(value as TCountry);
    }
  };

  const [countries, setCountries] = React.useState<
    Array<{ country: string; abbreviation: string }>
  >([]);

  useEffect(() => {
    const countries_json_data = require("@/assets/json/countries.json");
    setCountries(countries_json_data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originInputref = useRef(null);
  const nationaliteInputref = useRef(null);
  const originAutreInputref = useRef(null);
  const nationaliteAutreInputref = useRef(null);

  const goodNullBooleanValue = (
    val: "" | "true" | "false" | string
  ): null | boolean => {
    switch (val) {
      case "":
        return null;
      case "true":
        return true;
      case "false":
        return false;
      default:
        return null;
    }
  };
  const goodNullBooleanValueReverse = (val: any): "" | "true" | "false" => {
    switch (val) {
      case null:
        return "";
      case true:
        return "true";
      case false:
        return "false";
      default:
        return "";
    }
  };

  const validationSchema = yup.object().shape(
    {
      origine: yup.string().when("origine", (val, schema) => {
        let origin_val =
          (originInputref as React.MutableRefObject<any>).current.value || "";
        if (origin_val === "") {
          return yup
            .string()
            .required(" l'origine principale est obligatoire ");
        } else if (getAbbreviation(origin_val) === "") {
          setOrigine({
            country: origin_val,
            abbreviation: getAbbreviation(origin_val),
          });
          return yup.string().test({
            name: "incorrect_country",
            message: " Ce pays n'est pas valide ",
            test: function (value) {
              let result = true;
              if (getAbbreviation(origin_val) === "") {
                result = false;
              }
              return result;
            },
          });
        } else {
          setOrigine({
            country: origin_val,
            abbreviation: getAbbreviation(origin_val),
          });
          return yup.string().notRequired();
        }
      }),

      origine_autre: yup.string().when("origine", (val, schema) => {
        let origin_autre_val =
          (originAutreInputref as React.MutableRefObject<any>).current.value ||
          "";
        if (origin_autre_val === "") {
          return yup.string().notRequired();
        } else if (getAbbreviation(origin_autre_val) === "") {
          setOrigineAutre({
            country: origin_autre_val,
            abbreviation: getAbbreviation(origin_autre_val),
          });
          return yup.string().test({
            name: "incorrect_country",
            message: " Ce pays n'est pas valide ",
            test: function (value) {
              let result = true;
              if (getAbbreviation(origin_autre_val) === "") {
                result = false;
              }
              return result;
            },
          });
        } else {
          setOrigineAutre({
            country: origin_autre_val,
            abbreviation: getAbbreviation(origin_autre_val),
          });
          return yup.string().notRequired();
        }
      }),

      nationalite: yup.string().when("nationalite", (val, schema) => {
        let nationalite_val =
          (nationaliteInputref as React.MutableRefObject<any>).current.value ||
          "";
        if (nationalite_val === "") {
          return yup.string().required(" la nationalité est obligatoire ");
        } else if (getAbbreviation(nationalite_val) === "") {
          setOrigine({
            country: nationalite_val,
            abbreviation: getAbbreviation(nationalite_val),
          });
          return yup.string().test({
            name: "incorrect_country",
            message: " Ce pays n'est pas valide ",
            test: function (value) {
              let result = true;
              if (getAbbreviation(nationalite_val) === "") {
                result = false;
              }
              return result;
            },
          });
        } else {
          setNationalite({
            country: nationalite_val,
            abbreviation: getAbbreviation(nationalite_val),
          });
          return yup.string().notRequired();
        }
      }),

      nationalite_autre: yup.string().when("nationalite", (val, schema) => {
        let nationalite_autre_val =
          (nationaliteAutreInputref as React.MutableRefObject<any>).current
            .value || "";
        if (nationalite_autre_val === "") {
          return yup.string().notRequired();
        } else if (getAbbreviation(nationalite_autre_val) === "") {
          setNationaliteAutre({
            country: nationalite_autre_val,
            abbreviation: getAbbreviation(nationalite_autre_val),
          });
          return yup.string().test({
            name: "incorrect_country",
            message: " Ce pays n'est pas valide ",
            test: function (value) {
              let result = true;
              if (getAbbreviation(nationalite_autre_val) === "") {
                result = false;
              }
              return result;
            },
          });
        } else {
          setNationaliteAutre({
            country: nationalite_autre_val,
            abbreviation: getAbbreviation(nationalite_autre_val),
          });
          return yup.string().notRequired();
        }
      }),

      ethnie: yup.string().required(" l'ethnie est obligatoire "),
      situation_matrimoniale: yup
        .string()
        .required("le statut matrimonial est obligatoire "),

      have_money: yup
        .string()
        .when("situation_matrimoniale", (situation_matrimoniale, schema) => {
          if (
            (situation_matrimoniale as any) === "married" &&
            memberProfileFromStore?.infos?.identity?.gender === "MALE"
          )
            return yup.string().required("Ce champ est obligatoire");
          return yup.string().default("");
        }),

      is_family_know_before_marriage: yup
        .string()
        .when("situation_matrimoniale", (situation_matrimoniale, schema) => {
          if (
            (situation_matrimoniale as any) === "married" &&
            memberProfileFromStore?.infos?.identity?.gender === "MALE"
          )
            return schema.required("Ce champ est obligatoire");
          return schema.default("");
        }),

      fair_in_sharing_nights: yup
        .string()
        .when("situation_matrimoniale", (situation_matrimoniale, schema) => {
          if (
            (situation_matrimoniale as any) === "married" &&
            memberProfileFromStore?.infos?.identity?.gender === "MALE"
          )
            return schema.required("Ce champ est obligatoire");
          return schema.default("");
        }),

      ethnie_recherchee: yup
        .array()
        .min(1, " l'ethnie recherchée est obligatoire "),
      height: yup
        .number()
        .min(50, " la taille minimum est de 50cm ")
        .max(230, " la taille maximum est de 230cm "),
      description: yup.string().required(" la description est obligatoire "),
      profil_recherche: yup
        .string()
        .required(" le profil recherché est obligatoire "),
    },
    [
      ["origine", "origine"],
      ["nationalite", "nationalite"],
    ]
  );

  const formik = useFormik({
    initialValues: {
      origine: origine.country || "",
      origine_autre: origineAutre.country || "",
      nationalite: nationalite.country || "",
      nationalite_autre: nationaliteAutre.country || "",
      ethnie: memberProfileFromStore?.infos?.profile?.ethnicity || "",
      situation_matrimoniale:
        memberProfileFromStore?.infos?.profile?.maritalStatus || "",

      have_money: goodNullBooleanValueReverse(
        memberProfileFromStore?.infos?.profile?.isFamilyKnowBeforeMarriage // <-- attention à changer aveec la bonne variable
      ),
      is_family_know_before_marriage: goodNullBooleanValueReverse(
        memberProfileFromStore?.infos?.profile?.haveMoney
      ),
      fair_in_sharing_nights: goodNullBooleanValueReverse(
        memberProfileFromStore?.infos?.profile?.isFairInSharingNights
      ),

      ethnie_recherchee:
        memberProfileFromStore?.infos?.profile?.ethnicitiesWanted || [],
      lieux: memberProfileFromStore?.infos?.profile?.childhoodPlace || "",
      avoir_enfants: checkTrueOrFalse(
        memberProfileFromStore?.infos?.profile?.haveChildren
      ),
      garçons_a_charge:
        memberProfileFromStore?.infos?.profile?.dependentBoys || 0,
      filles_a_charge:
        memberProfileFromStore?.infos?.profile?.dependentGirls || 0,
      garde_enfants_hijra:
        memberProfileFromStore?.infos?.profile?.hijraChildren || "",
      accepter_enfants: checkTrueOrFalse(
        memberProfileFromStore?.infos?.profile?.acceptAnothersChildren
      ),
      nbr_enfants_accepte:
        memberProfileFromStore?.infos?.profile?.acceptableNbChildren || 1,
      profession_activite:
        memberProfileFromStore?.infos?.profile?.occupation || "",
      description: memberProfileFromStore?.infos?.profile?.description || "",
      bodyshape: memberProfileFromStore?.infos?.profile?.fatness || "",
      height: memberProfileFromStore?.infos?.profile?.height || 0,
      profil_recherche:
        memberProfileFromStore?.infos?.profile?.profilewanted || "",
      criteres_rehibitoires:
        memberProfileFromStore?.infos?.profile?.prohibitiveCriteria || "",
    },
    validationSchema: validationSchema,
    validate: (values) => {},
    onSubmit: (values) => {
      if (_.isEqual(formik.initialValues, values)) {
        Toastify(`Aucun changement apporté à votre profil.`, "warning", 3000);
      } else {
        const dataToSend = {
          category: "profile",
          homeCountry: origine.country.trim(),
          homeCountryCode: origine.abbreviation.trim(),
          otherHomeCountry: origineAutre.country.trim(),
          otherHomeCountryCode: origineAutre.abbreviation.trim(),
          citizenship: nationalite.country.trim(),
          citizenshipCode: nationalite.abbreviation.trim(),
          otherCitizenship: nationaliteAutre.country.trim(),
          otherCitizenshipCode: nationaliteAutre.abbreviation.trim(),
          ethnicity: values.ethnie.trim(),
          ethnicitiesWanted: values.ethnie_recherchee,
          maritalStatus: values.situation_matrimoniale.trim(),

          haveMoney: goodNullBooleanValue(values.have_money),
          isFamilyKnowBeforeMarriage: goodNullBooleanValue(
            values.is_family_know_before_marriage
          ),
          isFairInSharingNights: goodNullBooleanValue(
            values.fair_in_sharing_nights
          ),

          haveChildren: values.avoir_enfants,
          dependentBoys: values.garçons_a_charge,
          dependentGirls: values.filles_a_charge,
          hijraChildren: values.garde_enfants_hijra.trim(),
          childhoodPlace: values.lieux.trim(),
          acceptAnothersChildren: values.accepter_enfants,
          acceptableNbChildren: values.nbr_enfants_accepte,
          occupation: values.profession_activite.trim(),
          description: values.description.trim(),
          profilewanted: values.profil_recherche.trim(),
          prohibitiveCriteria: values.criteres_rehibitoires.trim(),
          fatness: values.bodyshape.trim(),
          height: values.height,
        };
        console.log(dataToSend);
        profilStepSubmit(dataToSend);
      }
    },
  });

  const navigate = useNavigate();
  const profilStepSubmit = (data: any) => {
    // Toastify('success',`As salemou 'alaikoum ${'Landry'}, tu es maintenant connectée.`);
    setLoading(true);

    apiSaveProfile(data)
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const profile = response.data as TMemberProfile;
        store.dispatch(updateMemberProfile({ ...profile }));
        Toastify(
          `Les informations de votre profil ont bien été soumises à notre équipe de modération`,
          "success",
          5000
        );
        chooseStep(1);
        navigate(routePaths.profileChildren.myAccountChildren.formAbsolute, {
          replace: true,
          state: { formStep: "2" },
        });
        scrollTop();
      })
      .catch((error: any) => {
        setLoading(false);

        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const reset = () => {
    formik.setValues({
      origine: "",
      origine_autre: "",
      nationalite: "",
      nationalite_autre: "",
      ethnie: "",
      situation_matrimoniale: "",
      have_money: "",
      is_family_know_before_marriage: "",
      fair_in_sharing_nights: "",
      ethnie_recherchee: [],
      lieux: "",
      avoir_enfants: false,
      garçons_a_charge: 0,
      filles_a_charge: 0,
      garde_enfants_hijra: "",
      accepter_enfants: false,
      nbr_enfants_accepte: 0,
      profession_activite: "",
      description: "",
      profil_recherche: "",
      criteres_rehibitoires: "",
      bodyshape: "",
      height: 0,
    });
    setOrigine({ country: "", abbreviation: "" });
    setOrigineAutre({ country: "", abbreviation: "" });
    setNationalite({ country: "", abbreviation: "" });
    setNationaliteAutre({ country: "", abbreviation: "" });
  };

  const submitClick = useCallback(() => {
    if (Object.keys(formik.errors).length > 0) {
      Toastify(
        `Il y a une erreur dans le formulaire. Veuillez remplir correctement le formulaire svp!`,
        "warning",
        5000
      );
    }
  }, [formik.errors]);

  const getAbbreviation = (selected_country: string): string => {
    let result = "";

    result =
      countries.find((x) => x.country === selected_country)?.abbreviation || "";

    return result;
  };

  useEffect(() => {
    if (formik.values.situation_matrimoniale !== "MARRIED") {
      formik.setFieldValue("have_money", "");
      formik.setFieldValue("is_family_know_before_marriage", "");
      formik.setFieldValue("fair_in_sharing_nights", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="form-part-profil" onSubmit={formik.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              id="country-select-origine"
              options={countries}
              value={origine}
              onChange={handleOrigineChange}
              autoHighlight
              getOptionLabel={(option) => option.country}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <InlineIcon
                    style={{ borderRadius: "50%" }}
                    icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                  />{" "}
                  &nbsp;
                  {option.country}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <InlineIcon
                    className="flag"
                    icon={`flag:${origine.abbreviation.toLowerCase()}-1x1`}
                  />
                  <TextField
                    {...params}
                    placeholder="Choisir une origine"
                    variant="outlined"
                    label="Votre origine principale*"
                    name="origine"
                    inputRef={originInputref}
                    onChange={formik.handleChange}
                    value={formik.values.origine}
                    error={
                      formik.touched.origine && Boolean(formik.errors.origine)
                    }
                    helperText={formik.touched.origine && formik.errors.origine}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              id="country-select-origine-autre"
              options={countries}
              value={origineAutre}
              onChange={handleOrigineAutreChange}
              autoHighlight
              getOptionLabel={(option) => option.country}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <InlineIcon
                    style={{ borderRadius: "50%" }}
                    icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                  />{" "}
                  &nbsp;
                  {option.country}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <InlineIcon
                    className="flag"
                    icon={`flag:${origineAutre.abbreviation.toLowerCase()}-1x1`}
                  />
                  <TextField
                    {...params}
                    placeholder="Choisir une origine"
                    variant="outlined"
                    label="2nd origine (facultative)"
                    name="origine_autre"
                    inputRef={originAutreInputref}
                    onChange={formik.handleChange}
                    value={formik.values.origine_autre}
                    error={
                      formik.touched.origine_autre &&
                      Boolean(formik.errors.origine_autre)
                    }
                    helperText={
                      formik.touched.origine_autre &&
                      formik.errors.origine_autre
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              id="country-select-nationalite"
              options={countries}
              value={nationalite}
              onChange={handleNationaliteChange}
              autoHighlight
              getOptionLabel={(option) => option.country}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <InlineIcon
                    style={{ borderRadius: "50%" }}
                    icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                  />{" "}
                  &nbsp;
                  {option.country}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <InlineIcon
                    className="flag"
                    icon={`flag:${nationalite.abbreviation.toLowerCase()}-1x1`}
                  />
                  <TextField
                    {...params}
                    placeholder="Choisir une nationalité"
                    variant="outlined"
                    label="Votre nationalité*"
                    name="nationalite"
                    inputRef={nationaliteInputref}
                    onChange={formik.handleChange}
                    value={formik.values.nationalite}
                    error={
                      formik.touched.nationalite &&
                      Boolean(formik.errors.nationalite)
                    }
                    helperText={
                      formik.touched.nationalite && formik.errors.nationalite
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Autocomplete
              id="country-select-nationalite-autre"
              options={countries}
              value={nationaliteAutre}
              onChange={handleNationaliteAutreChange}
              autoHighlight
              getOptionLabel={(option) => option.country}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <InlineIcon
                    style={{ borderRadius: "50%" }}
                    icon={`flag:${option.abbreviation.toLowerCase()}-1x1`}
                  />{" "}
                  &nbsp;
                  {option.country}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <InlineIcon
                    className="flag"
                    icon={`flag:${nationaliteAutre.abbreviation.toLowerCase()}-1x1`}
                  />
                  <TextField
                    {...params}
                    placeholder="Choisir une autre nationalité"
                    variant="outlined"
                    label="Autre nationalité (facultative)"
                    name="nationalite_autre"
                    inputRef={nationaliteAutreInputref}
                    onChange={formik.handleChange}
                    value={formik.values.nationalite_autre}
                    error={
                      formik.touched.nationalite_autre &&
                      Boolean(formik.errors.nationalite_autre)
                    }
                    helperText={
                      formik.touched.nationalite_autre &&
                      formik.errors.nationalite_autre
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <FormControl
              fullWidth
              error={formik.touched.ethnie && Boolean(formik.errors.ethnie)}
            >
              <InputLabel id="id-select-ethnie">Mon ethnie*</InputLabel>
              <Select
                labelId="id-select-ethnie"
                id="select-ethnie"
                name="ethnie"
                value={formik.values.ethnie}
                label="Mon ethnie*"
                onChange={formik.handleChange}
              >
                {ethnies.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                error={formik.touched.ethnie && Boolean(formik.errors.ethnie)}
              >
                {formik.errors.ethnie as string}
              </FormHelperText>
            </FormControl>
            {/* <Autocomplete
              id="id-ethhnie-select"
              options={ethnies}
              value={ethnie}
              onChange={handleEthnieChange}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    placeholder="Choisir une ethnie"
                    variant="outlined"
                    label="Mon ethnie"
                    name="ethnie"
                    onChange={formik.handleChange}
                    value={formik.values.ethnie}
                    error={formik.touched.ethnie && Boolean(formik.errors.ethnie)}
                    helperText={formik.touched.ethnie && formik.errors.ethnie}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                </>
              )}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            {memberProfileFromStore?.infos?.identity?.gender === "MALE" && (
              <FormControl
                fullWidth
                error={
                  formik.dirty && Boolean(formik.errors.situation_matrimoniale)
                }
              >
                <InputLabel id="select-matrimonial-male">
                  Votre statut matrimonial*
                </InputLabel>
                <Select
                  labelId="select-matrimonial-male"
                  id="select-matrimonial-male"
                  name="situation_matrimoniale"
                  value={formik.values.situation_matrimoniale}
                  label="Votre statut matrimonial*"
                  required
                  onChange={(e) => {
                    if (e.target.value !== "MARRIED") {
                      formik.setFieldValue("have_money", "");
                      formik.setFieldValue(
                        "is_family_know_before_marriage",
                        ""
                      );
                      formik.setFieldValue("fair_in_sharing_nights", "");
                    }
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value={"NEVER_MARRIED"}>Jamais Marié</MenuItem>
                  <MenuItem value={"DIVORCED"}>Divorcé</MenuItem>
                  <MenuItem value={"WIDOW"}>Veuf</MenuItem>
                  <MenuItem value={"MARRIED"}>Marié</MenuItem>
                </Select>
                <FormHelperText
                  error={
                    formik.dirty &&
                    Boolean(formik.errors.situation_matrimoniale)
                  }
                >
                  {formik.errors.situation_matrimoniale as string}
                </FormHelperText>
              </FormControl>
            )}
            {memberProfileFromStore?.infos?.identity?.gender === "FEMALE" && (
              <FormControl
                fullWidth
                error={
                  formik.touched.situation_matrimoniale &&
                  Boolean(formik.errors.situation_matrimoniale)
                }
              >
                <InputLabel id="id-select-matrimonial">
                  Votre statut matrimonial*
                </InputLabel>
                <Select
                  labelId="id-select-matrimonial"
                  id="select-matrimonial"
                  name="situation_matrimoniale"
                  value={formik.values.situation_matrimoniale}
                  label="Votre statut matrimonial*"
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"NEVER_MARRIED"} selected>
                    Jamais Mariée
                  </MenuItem>
                  <MenuItem value={"DIVORCED"}>Divorcée</MenuItem>
                  <MenuItem value={"WIDOW"}>Veuve</MenuItem>
                </Select>
                <FormHelperText
                  error={
                    formik.touched.situation_matrimoniale &&
                    Boolean(formik.errors.situation_matrimoniale)
                  }
                >
                  {formik.errors.situation_matrimoniale as string}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          {memberProfileFromStore?.infos?.identity?.gender === "MALE" &&
            formik.values.situation_matrimoniale === "MARRIED" && (
              <div className="field-container">
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl
                    fullWidth
                    color="primary"
                    className="niveau-radio-row"
                    error={formik.values.have_money === ""}
                  >
                    <label id="id-have-money">
                      Avez-vous les moyens financiers pour un second foyer
                      (loger, nourriture vêtements) ?
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="id-have-money"
                      className="row-radio-buttons-group"
                      name="have_money"
                      value={formik.values.have_money}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={"Oui"}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={"Non"}
                      />
                    </RadioGroup>
                    <FormHelperText error={formik.values.have_money === ""}>
                      {formik.errors.have_money}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl
                    fullWidth
                    color="primary"
                    className="niveau-radio-row"
                    error={formik.values.is_family_know_before_marriage === ""}
                  >
                    <label id="id-is_family_know_before_marriage">
                      Votre famille sera t'elle mise au courant avant le mariage
                      ?
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="id-is_family_know_before_marriage"
                      className="row-radio-buttons-group"
                      name="is_family_know_before_marriage"
                      value={formik.values.is_family_know_before_marriage}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={"Oui"}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={"Non"}
                      />
                    </RadioGroup>
                    <FormHelperText
                      error={
                        formik.values.is_family_know_before_marriage === ""
                      }
                    >
                      {formik.errors.is_family_know_before_marriage}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <FormControl
                    fullWidth
                    color="primary"
                    className="niveau-radio-row"
                    error={formik.values.fair_in_sharing_nights === ""}
                  >
                    <label id="id-fair_in_sharing_nights">
                      Serez vous équitable dans le partage des nuits ?
                    </label>
                    <RadioGroup
                      row
                      aria-labelledby="id-fair_in_sharing_nights"
                      className="row-radio-buttons-group"
                      name="fair_in_sharing_nights"
                      value={formik.values.fair_in_sharing_nights}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value={"true"}
                        control={<Radio />}
                        label={"Oui"}
                      />
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={"Non"}
                      />
                    </RadioGroup>
                    <FormHelperText
                      error={formik.values.fair_in_sharing_nights === ""}
                    >
                      {formik.errors.fair_in_sharing_nights}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </div>
            )}

          <Grid item xs={12} sm={12} lg={12}>
            <MultipleSelectChip
              name="ethnie_recherchee"
              selectOptions={ethnies}
              value={formik.values.ethnie_recherchee}
              onChange={formik.handleChange}
              labelId="id-select-ethnie-recherchee"
              label="Ethnie(s) recherchée(s)*"
              errorText={formik.errors.ethnie_recherchee as string}
              error={
                formik.touched.ethnie_recherchee &&
                Boolean(formik.errors.ethnie_recherchee)
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              placeholder="Lieu(x) où vous êtes né(e) et où vous avez grandi"
              variant="outlined"
              label="Lieu(x) où vous êtes né(e) et où vous avez grandi"
              name="lieux"
              onChange={formik.handleChange}
              value={formik.values.lieux}
              error={formik.touched.lieux && Boolean(formik.errors.lieux)}
              helperText={
                formik.touched.lieux && (formik.errors.lieux as string)
              }
              type="text"
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={formik.values.avoir_enfants ? 12 : 6}
          >
            <div className="switch-container">
              <Typography> Avez-vous des enfants ?</Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.avoir_enfants ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="avoir_enfants"
                      checked={formik.values.avoir_enfants}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (formik.values.avoir_enfants === false) {
                          formik.setFieldValue("garçons_a_charge", 0);
                          formik.setFieldValue("filles_a_charge", 0);
                        }
                      }}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>
          {formik.values.avoir_enfants && (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.garçons_a_charge &&
                    Boolean(formik.errors.garçons_a_charge)
                  }
                >
                  <InputLabel id="id-select-garçon">Garçon(s)</InputLabel>
                  <Select
                    labelId="id-select-garçon"
                    id="select-garçon"
                    name="garçons_a_charge"
                    value={formik.values.garçons_a_charge as any}
                    label="Garçon(s) "
                    onChange={formik.handleChange}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                      (value: number, index: number) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {" "}
                            {value}{" "}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  <FormHelperText
                    error={
                      formik.touched.garçons_a_charge &&
                      Boolean(formik.errors.garçons_a_charge)
                    }
                  >
                    {formik.errors.garçons_a_charge as string}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.filles_a_charge &&
                    Boolean(formik.errors.filles_a_charge)
                  }
                >
                  <InputLabel id="id-select-filles">Fille(s)</InputLabel>
                  <Select
                    labelId="id-select-filles"
                    id="select-filles"
                    name="filles_a_charge"
                    value={formik.values.filles_a_charge as any}
                    label="Fille(s)"
                    onChange={formik.handleChange}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                      (value: number, index: number) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {" "}
                            {value}{" "}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  <FormHelperText
                    error={
                      formik.touched.filles_a_charge &&
                      Boolean(formik.errors.filles_a_charge)
                    }
                  >
                    {formik.errors.filles_a_charge as string}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} sx={{ height: "130px" }}>
                <div className="text-area">
                  <Typography className="text-area-label">
                    {" "}
                    La garde de vos enfants en cas de Hijra{" "}
                  </Typography>
                  <textarea
                    className="text-area-input"
                    rows={3}
                    name="garde_enfants_hijra"
                    onChange={formik.handleChange}
                    value={formik.values.garde_enfants_hijra}
                  ></textarea>
                </div>
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} lg={12} xl={6}>
            <div className="switch-container">
              <Typography> Acceptez-vous les enfants d’un autre ?</Typography>
              <FormGroup>
                <FormControlLabel
                  label={formik.values.accepter_enfants ? "Oui" : "Non"}
                  control={
                    <Switch
                      name="accepter_enfants"
                      checked={formik.values.accepter_enfants}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (formik.values.accepter_enfants === false) {
                          formik.setFieldValue("nbr_enfants_accepte", 0);
                        }
                      }}
                    />
                  }
                />
              </FormGroup>
            </div>
          </Grid>
          {formik.values.accepter_enfants && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                lg={!formik.values.avoir_enfants ? 12 : 6}
              >
                <TextField
                  placeholder=""
                  variant="outlined"
                  label="Combien d'enfants acceptez-vous ?"
                  name="nbr_enfants_accepte"
                  onChange={formik.handleChange}
                  value={formik.values.nbr_enfants_accepte}
                  error={
                    formik.touched.nbr_enfants_accepte &&
                    Boolean(formik.errors.nbr_enfants_accepte)
                  }
                  helperText={
                    formik.touched.nbr_enfants_accepte &&
                    (formik.errors.nbr_enfants_accepte as string)
                  }
                  type="number"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              placeholder=""
              variant="outlined"
              label="Votre profession / Votre activité"
              name="profession_activite"
              onChange={formik.handleChange}
              value={formik.values.profession_activite}
              error={
                formik.touched.profession_activite &&
                Boolean(formik.errors.profession_activite)
              }
              helperText={
                formik.touched.profession_activite &&
                (formik.errors.profession_activite as string)
              }
              type="text"
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            <TextField
              placeholder=""
              variant="outlined"
              label="Votre taille"
              name="height"
              onChange={formik.handleChange}
              value={formik.values.height}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={
                formik.touched.height && (formik.errors.height as string)
              }
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            <FormControl
              fullWidth
              error={
                formik.dirty && Boolean(formik.errors.situation_matrimoniale)
              }
            >
              <InputLabel id="select-bodyshape">Votre corpulence</InputLabel>
              <Select
                labelId="select-bodyshape"
                id="select-bodyshape"
                name="bodyshape"
                value={formik.values.bodyshape}
                label="Votre corpulence"
                required
                onChange={formik.handleChange}
              >
                {bodyshapeFromStore.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                error={formik.dirty && Boolean(formik.errors.bodyshape)}
              >
                {formik.errors.bodyshape as string}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error:
                  formik.touched.description &&
                  Boolean(formik.errors.description),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Votre description*{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={7}
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
              ></textarea>
              {formik.touched.description &&
                Boolean(formik.errors.description) && (
                  <FormHelperText>
                    {" "}
                    {formik.errors.description as string}{" "}
                  </FormHelperText>
                )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div
              className={classNames("text-area", {
                error:
                  formik.touched.profil_recherche &&
                  Boolean(formik.errors.profil_recherche),
              })}
            >
              <Typography className="text-area-label">
                {" "}
                Le profil recherché*{" "}
              </Typography>
              <textarea
                className="text-area-input"
                rows={7}
                name="profil_recherche"
                onChange={formik.handleChange}
                value={formik.values.profil_recherche}
              ></textarea>
              {formik.touched.profil_recherche &&
                Boolean(formik.errors.profil_recherche) && (
                  <FormHelperText>
                    {" "}
                    {formik.errors.profil_recherche as string}{" "}
                  </FormHelperText>
                )}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <div className="text-area">
              <Typography className="text-area-label">
                {" "}
                Vos critères rédhibitoires
              </Typography>
              <textarea
                className="text-area-input"
                rows={7}
                name="criteres_rehibitoires"
                onChange={formik.handleChange}
                value={formik.values.criteres_rehibitoires}
              ></textarea>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={4}
        columnSpacing={2}
        sx={{ justifyContent: "flex-end", marginTop: "30px" }}
      >
        <Grid item xs={12} sm={12} lg={3} xl={2}>
          <Button
            variant="outlined"
            color="tertiary"
            onClick={() => {
              reset();
            }}
          >
            {" "}
            Effacer
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={5}>
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            color="primary"
            type="submit"
            onClick={submitClick}
          >
            {" "}
            Valider et passer à "Aspect religieux"
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfilStep;
